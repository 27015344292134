import useMusic from './useMusic'
import useClickSound from './useClickSound'
import useAmbientSound from './useAmbientSound'

const Sound = () => {
    useClickSound()
    useMusic()
    useAmbientSound()

    return null
}
export default Sound
