export * from './topics'
export * from './views'
export * from './sound'

export const DETAIL_VIEW_STATE = Object.freeze({
    none: 'none',
    improving: 'improving',
    improved: 'improved',
    analyzing: 'analyzing',
    analyzingComplete: 'analyzingComplete',
    analyzed: 'analyzed',
})

export const HAS_TOUCH_POINTS = navigator.maxTouchPoints > 0
export const IS_DEV_MODE = process.env.NODE_ENV === 'development'
// eslint-disable-next-line no-var, import/no-mutable-exports, vars-on-top
export var TIME_SCALE = 1
export const SET_TIME_SCALE = (value) => { TIME_SCALE = value }

export const FROM_TO_DETAIL_VIEW_PAGE_TRANSITION_TIMEOUT = 500 / TIME_SCALE
export const PAGE_TRANSITION_TIMEOUT = 1000 / TIME_SCALE
export const PAGE_TRANSITION_ANIMATION_TIMEOUT = PAGE_TRANSITION_TIMEOUT - (300 / TIME_SCALE)
export const MAIN_ROUTE_TRANSITION_TIMEOUT = 1000 / TIME_SCALE
export const MAINVIEW_ZOOM_TRANSITION_TIME = 200 / TIME_SCALE

export const LONG_PRESS_THRESHOLD = 1000
export const ANALYZE_DURATION = 3 / TIME_SCALE
export const SIMULATION_DURATION = 3 / TIME_SCALE

export const SKIP_OUTRO = false // will skip the webgl outro and move faster to dashboard
