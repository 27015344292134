import styled from 'styled-components'
import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import MuteSound from './MuteSound'
import { ReactComponent as FullScreenIcon } from './FullScreen/Fullscreen.svg'
import MagneticButton from '../MagneticButton/MagneticButton'
import { useCurrentView } from '../../hooks'
import Restart from './Restart/Restart'
import { useGlobalSoundSettings } from '../../context'
import { vp } from '../../common/layout'
import handleFullScreen from '../../utils/fullScreenHandler'
import LanguageSwitch from './LanguageSwitch/LanguageSwitch'

const Root = styled.div({
    position: 'fixed',
    right: '5px',
    zIndex: 10,
})

const IconContainer = styled.div`
    width: 64px;
    height: 64px;

    ${vp.small(`
        width: 40px;
        height: 40px;
`)}

    > * {
        width: 35px;
        height: 35px;

        ${vp.small(`
         width: 27px;
        height: 27px;
`)}}
`

const Container = styled.div`
    display: flex;
    align-items: center;
    margin: 1rem 1rem 0 0;

    @media only screen and (max-height: 665px) and (orientation: landscape) {
        margin: 0;
    }

    .magnetic {
        width: 100%;
        height: 100%;
    }

    .magnetic :hover ${IconContainer}::before,  .magnetic :hover ${IconContainer}::after {
        position: absolute;
        top: 50%;
        left: 50%;
        content: '';
        border-radius: 50%;
        transition: all;
        transition-timing-function: linear;
        transition-duration: 300ms;
        transform: translate(-50%, -50%);
        pointer-events: none;
        z-index: -1;
        height: 80%;
        width: 80%;
    }

    @media (hover: hover) {
        .magnetic :hover ${IconContainer}::before {
            background: #9FB8FF;
            opacity: 0.1;
        }

        .magnetic :hover ${IconContainer}::after {
            background: #3861D8;
            opacity: 0.2;
            height: 100%;
            width: 100%;
        }
    }

    @media (hover: none) {
        .magnetic :active ${IconContainer}::before {
            background: #9FB8FF;
            opacity: 0.1;
        }

        .magnetic :active ${IconContainer}::after {
            background: #3861D8;
            opacity: 0.2;
            height: 100%;
            width: 100%;
        }
    }

    ${vp.small(`
        margin:0;
`)}

`
const IconLabel = styled.span`
    font-family: '72 Light';
    font-size: 0.688rem;
    color: rgba(219, 242, 255, 1);
    text-align: center;
    position: absolute;
    bottom: -0.688rem;
    ${vp.small(`
        font-size: 0.5rem;
    `)}
`

const ButtonContainer = styled.div`
    display: flex; 
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    transition: all 0.5 ease-in-out;
    
    ${IconLabel} {
        opacity: 0;
    }

    ${MagneticButton} {
        :hover +${IconLabel} {
            opacity: 1;
        }
    }
`

const StyledMuteSound = styled(MuteSound)`
    width: 54px;
    height: 54px;

    svg {
        padding-bottom: 3px;
    }
`

const StyledFullScreen = styled(FullScreenIcon)({
    // width: '70%',
    // height: '70%',
    pointerEvents: 'auto',
    cursor: 'pointer',
})

const magneticStyle = { padding: '0.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }

function TopControlPanel({ className }) {
    const { t } = useTranslation()
    const { mute: isMuted } = useGlobalSoundSettings()
    const currentView = useCurrentView()

    const handleRestart = () => {
        window.history.pushState({ }, null, '/')
        window.history.go()
    }

    const handleUserKeyPress = (event) => {
        if (event.key === 'F11') {
            handleFullScreen()
            // fscreen.requestFullscreen()
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleUserKeyPress)

        return () => {
            window.removeEventListener('keydown', handleUserKeyPress)
        }
    })

    return (
        <Root>
            <Container className={className}>
                {currentView?.name === 'AnniversaryView' || currentView?.name === 'EndView' ? (
                    <ButtonContainer onClick={handleRestart}>
                        <MagneticButton
                            className="magnetic"
                            tolerance={2}
                            style={magneticStyle}
                        >
                            <IconContainer className="centerItem">
                                <Restart />
                            </IconContainer>
                        </MagneticButton>
                        <IconLabel>{t('global.restart')}</IconLabel>
                    </ButtonContainer>
                ) : null}

                <ButtonContainer>
                    <MagneticButton
                        className="magnetic"
                        tolerance={2}
                        style={magneticStyle}
                    >
                        <IconContainer className="centerItem">
                            <LanguageSwitch />
                        </IconContainer>
                    </MagneticButton>
                    <IconLabel>{t('global.language')}</IconLabel>
                </ButtonContainer>

                {!isMobile
                    && (
                        <ButtonContainer
                            onClick={handleFullScreen}
                        >
                            <MagneticButton
                                className="magnetic"
                                tolerance={2}
                                style={magneticStyle}
                            >
                                <IconContainer className="centerItem">
                                    <StyledFullScreen />
                                </IconContainer>
                            </MagneticButton>
                            <IconLabel>{document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement ? t('global.exitFullscreen') : t('global.enterFullscreen')}</IconLabel>
                        </ButtonContainer>
                    )}

                <ButtonContainer>
                    <MagneticButton
                        className="magnetic"
                        tolerance={2}
                        style={magneticStyle}
                    >
                        <IconContainer className="centerItem">
                            <StyledMuteSound />
                        </IconContainer>
                    </MagneticButton>
                    <IconLabel>{isMuted ? t('global.unmute') : t('global.mute') }</IconLabel>
                </ButtonContainer>

            </Container>
        </Root>
    )
}

export default TopControlPanel
