import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import withMagneticButton from '../MagneticButton/withMagneticButton'
import { ReactComponent as IconArrow } from '../../common/assets/Icon-arrow.svg'
import { colors } from '../../common/color'

const Button = styled(withMagneticButton(styled.button`
    display: flex;
    z-index:0;
    align-items:center;
    justify-content:space-between;
    background: ${props => props.isWhite ? colors.button.primary.background : colors.button.secondary.background};
    border-radius: 50px;
    color: ${props => props.isWhite ? colors.button.primary.color : colors.button.secondary.color};
    border: none;
    ${props => props.outline && css`
        background: transparent;
        border: 2px solid ${colors.button.primary.background};
        color: ${colors.button.secondary.color};
        @media only screen and (max-width: 767px) {
            border-width: 1px;
        }
    `}
    font-family:'72 Bold';
    font-size: 14px;
    line-height: 14px;
    width: ${props => `${props.width}px`};
    height: 45px;
    padding: 12px 25px;
    letter-spacing: 0.525px;
    transition:all 0.1s ease-in;
    cursor: pointer;

    :link {
        color: inherit;
    }

    :active, :link{
        color: ${props => props.isWhite ? colors.button.primary.color : colors.button.secondary.color};
        transform:scale(0.9,0.9) translate3d(0, 3px, 0);
    }

    > svg {
        height:16px; 
        transform: ${props => props.isLefty ? 'rotate(-180deg)' : null};
    }

    > svg path {
        fill: ${props => props.isWhite ? colors.button.primary.color : colors.button.secondary.color};
    }

    :hover {
        background: ${props => props.isWhite ? colors.button.primary.backgroundHover : colors.button.secondary.backgroundHover};
        color: ${props => props.isWhite ? colors.button.primary.colorHover : colors.button.secondary.colorHover};

        > svg path {
            fill: ${props => props.isWhite ? colors.button.primary.colorHover : colors.button.secondary.colorHover};
        }
    }
`))``

const ButtonArrow = (props) => {
    const { isWhite, isLefty, width, text, onClick, children, alignLeft, alignRight, ...rest } = props

    const content = <span>{text || children}</span>

    return (
        <Button onClick={onClick} isWhite={isWhite} isLefty={isLefty} width={width} alignLeft={alignLeft} alignRight={alignRight} {...rest}>
            {!isLefty && content}
            <IconArrow />
            {isLefty && content}
        </Button>
    )
}

ButtonArrow.propTypes = {
    width: PropTypes.number,
    isWhite: PropTypes.bool,
    isLefty: PropTypes.bool,
    alignLeft: PropTypes.bool,
    alignRight: PropTypes.bool,
}

ButtonArrow.defaultProps = {
    width: 230,
    isWhite: false,
    isLefty: false,
    alignLeft: false,
    alignRight: false,
}

export default React.memo(ButtonArrow)
