import React, { useCallback, useMemo, useEffect } from 'react'
import { useGlobalContext } from './GlobalContext/GlobalContext'
import { Topics } from '../constants'

const initMenuState = {
    topics: JSON.parse(JSON.stringify(Topics)),
    menuHoverIndex: -1,
}

const defaultMenuContext = {
    state: initMenuState,
    setMenuHoverIndex: (index) => {},
    setMenuItemToImplemented: (index) => {},
    /**
    * @param {Array<{x: number, y: number}>} menuCoordinates pass all menu coordinates to update
    */
    updateMenuCoordinates: (menuCoordinates) => {},
}

const MenuContext = React.createContext(defaultMenuContext)
MenuContext.displayName = 'MenuContext'

export const useMenuContext = () => React.useContext(MenuContext)
export default MenuContext

export const SET_MENU_ITEM_TO_IMPLEMENTED = 'APP/MENU/SET_MENU_ITEM_TO_IMPLEMENTED'
export const SET_HOVER_INDEX = 'APP/MENU/SET_HOVER_INDEX'

export const menuReducer = (state = initMenuState, action) => {
    switch (action.type) {
    case SET_MENU_ITEM_TO_IMPLEMENTED:
        if (!state.topics[action.menuItemIndex].isImplemented) {
            const newItem = {
                ...state.topics[action.menuItemIndex],
                isImplemented: true,
            }

            return {
                ...state,
                topics: state.topics.map((item, index) => {
                    return index === action.menuItemIndex ? newItem : item
                }),
            }
        }
        return state
    case SET_HOVER_INDEX:
        return {
            ...state,
            menuHoverIndex: action.index,
        }
    default:
        throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export const MenuProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(menuReducer, initMenuState)
    const { state: { viewData } } = useGlobalContext()

    const setMenuItemToImplemented = useCallback((menuItemIndex) => {
        dispatch({ type: SET_MENU_ITEM_TO_IMPLEMENTED, menuItemIndex })
    }, [])

    const setMenuHoverIndex = useCallback((index) => {
        dispatch({ type: SET_HOVER_INDEX, index })
    }, [])

    useEffect(() => {
        Object.entries(viewData).forEach((item) => {
            if (item[0].startsWith('DetailView') && item[1].isImplemented) {
                setMenuItemToImplemented(item[1].index)
            }
        })
    }, [viewData, setMenuItemToImplemented])

    const value = useMemo(() => ({
        state,
        setMenuHoverIndex,
        setMenuItemToImplemented,
    }), [setMenuHoverIndex, setMenuItemToImplemented, state])

    return (
        <MenuContext.Provider value={value}>
            {children}
        </MenuContext.Provider>
    )
}
