import { useEffect, useState } from 'react'
import gsap from 'gsap'
import styled from 'styled-components'
import Lottie from 'react-lottie'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import animationData1 from './assets/loading.json'
import { ReactComponent as headphones } from './assets/headphones.svg'
import { Text, H1, H3 } from '../../common/text'
import { vp } from '../../common/layout'
import withMagneticButton from '../MagneticButton/withMagneticButton'
import ButtonArrow from '../Buttons/ButtonArrow'
import fscreen from '../../utils/fscreen'
import { colors } from '../../common/color'

const StyledContainer = styled.div`
  position: absolute;
  /* top:10%; */
  width: 100%;
  height: 100%;
  /* min-height:43.75rem; */
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
`

const Header = styled.div`
  margin-top:5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  @media only screen and (max-height: 450px) and (max-width: 350px) {
    margin-top: 7%;
    }
  ${vp.small(`
    margin-top:10%;
`)}

${vp.medium(`
    margin-top:1.6rem;
    font-size:0.6rem;
`)}
`

const Headline1 = styled(H3)`
font-weight:700;
font-size:2.5rem;
line-height:4.5rem;
${vp.small(`
    font-size: 1rem;
`)}
`

const Headline2 = styled(H3)`
padding: 0;
margin: 0;
font-weight:700;
font-size:1.5625rem;
text-align:center;
line-height:2.1875rem;
${vp.small(`
    max-width:85%;
    font-size: 0.9375rem;
    line-height: 1.4rem;
`)}
`

const Headline3 = styled(H1)`
padding: 0;
margin: 0;
font-size:4.375rem;
text-align:center;
color: ${colors.titleColor};
${vp.small(`
    font-size: 2.1875rem;
    max-width:95%;
    line-height: 2rem;
    margin-top:1.2rem;
`)}
`

const Container = styled.div`
  white-space: pre-line;
  position:absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  flex-direction: column;

  .header1, .header2, .header3 {
    opacity: 0;
  }

  .header2 {
    transform: translate(0%, -20%);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }

  .header3 {
    transform: translate(0%, 15%);
    transition: opacity 1.5s ease-out, transform 1.5s ease-out;
  }

  .header1 {
    &.show {
      opacity: 1;
      transition: opacity 1s ease-out;
    }
    &.hide {
      opacity: 0;
      transition: opacity 1.5s ease-out;
    }
  }

  .animate {
    opacity: 1;
    transform: translate(0,0);
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  min-height:600px;
  justify-content:center;
  align-items:center;
  margin-top: auto;
  margin-bottom: auto;
  ${vp.small(`
    font-size: 2rem;
    max-width:90%;
    height: 100vh;
`)}
`

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  margin-bottom: 3.125rem;
  ${vp.small(`
    margin-bottom: 1.5rem;    
`)}
@media only screen and (max-height: 450px) and (max-width: 350px) {
    margin-bottom: 1rem;    
    }
`
const FooterLink = styled(withMagneticButton(styled.a`
  color: inherit;
  margin-top: 15px;
  font-size: ${props => props.isHeadline ? '0.85rem' : '0.65rem'};
  padding: 0.5rem 0;
  transition: all 0.3s linear;
  /* word-break: normal; */
  text-align: center;
  &:hover {
    border-bottom: 1px solid white;
  }
  ${vp.small(`
    font-size: 0.625rem;
    padding:0.5rem;
    white-space: pre;
    vertical-align: middle;
  `)}
`))`
  ${vp.small(`
    padding: 0;
  `)}
`
const StyledText = styled(Text)`
font-weight:bold;
${vp.small(`
   white-space: pre-line;
   font-size: 0.75rem;
   text-align:center;
  `)}

${vp.medium(`
    font-size: 0.825rem;
`)}
`

const HeaderLink = styled(FooterLink)`
  opacity: 0;
  pointer-events: none;
  font-size: 1.8rem !important;
  margin-top:-30px !important;
  visibility: ${props => props.isFullscreen ? 'hidden' : 'visible'};
  transition:visibility 0.5s ease-in;
`

const StyledHeadphones = styled(headphones)`
  width: 2rem;
  height: 2rem;
  display: inline;
  margin-bottom:1rem;
  ${vp.small(`
    width: 1.5rem;
    height: 1.5rem;
  `)}

${vp.medium(`
    width: 1.7rem;
    height: 1.7rem;
`)}
`

const StyledButtonArrow = styled(ButtonArrow)`
  opacity: 0;
  pointer-events: none;
  ${vp.small(`
    transform: scale(0.8) !important;
  `)}
`

const SplashScreen = ({ onSlideEnd }) => {
    const [loading, setLoading] = useState(true)
    const { t } = useTranslation()
    const [showLink, setShowLink] = useState(false)
    const defaultOptions = {
        dots: {
            loop: true,
            autoplay: true,
            animationData: animationData1,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid meet',
            },
        },
    }

    useEffect(() => {
        document.body.classList.add('overflow-y-scroll')

        document.querySelector('.preloader')?.remove()

        return () => {
            document.body.classList.remove('overflow-y-scroll')
        }
    }, [])

    useEffect(() => {
        gsap.delayedCall(5, () => setLoading(false))
    }, [])

    useEffect(() => {
        if (loading) return null

        const timeline = gsap.timeline()
            .callWithDuration(() => document.querySelector('.header1').classList.add('show'), 1, '+=0.5')
            .callWithDuration(() => document.querySelector('.header1').classList.add('hide'), 1.5)
            .callWithDuration(() => document.querySelector('.header2').classList.add('animate'), 1)
            .callWithDuration(() => document.querySelector('.header3').classList.add('animate'), 1.5)
            .to(`.${StyledButtonArrow.styledComponentId}, .${HeaderLink.styledComponentId}`, {
                duration: 1,
                opacity: 1,
                pointerEvents: 'initial',
                ease: 'sine.out',
            }, '+=0.5')

        return () => {
            timeline.kill()
        }
    }, [loading])

    const onClick = () => {
        onSlideEnd && onSlideEnd()
    }

    const enterFullScreen = () => {
        fscreen.requestFullscreen(document.documentElement)
        onSlideEnd && onSlideEnd()
    }

    const onClickCookiePref = () => {
        const element = document.getElementById('teconsent')
        element.querySelector('a').click()
    }

    useEffect(() => {
        const handler = () => {
            if (fscreen.fullscreenElement !== null) {
                console.info('Entered fullscreen mode')
                setShowLink(true)
            } else {
                console.info('Exited fullscreen mode')
                setShowLink(false)
            }
        }

        fscreen.addEventListener('fullscreenchange', handler)

        return () => {
            fscreen.removeEventListener('fullscreenchange', handler)
        }
    }, [])

    return (
        <>
            <StyledContainer>
                <Header>
                    <StyledHeadphones viewBox="0 0 32 32" />
                    {!isMobile ? <StyledText>{t('splashScreen.turnOnSound')}</StyledText> : <StyledText>{t('splashScreen.turnOnSoundMobile')}</StyledText>}
                </Header>

                <Body>
                    {loading
                        ? (
                            <div className="fixed grid place-items-center">
                                <Lottie
                                    options={defaultOptions.dots}
                                    width={65}
                                    height={65}
                                    isStopped={false}
                                    isPaused={false}
                                />
                            </div>
                        ) : (
                            <Container>
                                <div className="flex flex-col items-center w-full">
                                    <Headline1 className="header1 absolute">{t('splashScreen.header1')}</Headline1>
                                    <Headline2 className="header2">{t('splashScreen.header2')}</Headline2>
                                    <Headline3 className="header3">{t('splashScreen.header3')}</Headline3>
                                </div>
                                <StyledButtonArrow width={250} isWhite className="m-8 scale-in" magnetic={true} onClick={onClick} text={t('splashScreen.cta1')} />
                                {!isMobile && <HeaderLink isHeadline isFullscreen={showLink} onClick={enterFullScreen} target="blank">{t('splashScreen.cta2')}</HeaderLink>}

                                {/* <StyledCircleButton className="m-8" magnetic={true} onClick={onClick}>{t('splashScreen.cta')}</StyledCircleButton> */}
                            </Container>
                        )}
                </Body>
            </StyledContainer>

            <Footer>
                <FooterLink href="https://www.sap.com/about/legal/privacy.html" target="blank"><span>{t('splashScreen.privacyPolicy')}</span></FooterLink>
                <FooterLink href="https://www.sap.com/corporate/en/legal/terms-of-use.html" target="blank"><span>{t('splashScreen.terms')}</span></FooterLink>
                <FooterLink href="https://www.sap.com/corporate/en/legal/impressum.html" target="blank"><span>{t('splashScreen.legal')}</span></FooterLink>
                <FooterLink onClick={onClickCookiePref}><span>{t('splashScreen.cookiePreferences')}</span></FooterLink>
            </Footer>
        </>
    )
}
export default SplashScreen
