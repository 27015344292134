import React, { useMemo, useState } from 'react'

/**
* @param {boolean}
*/
const noop = (value) => {}

const GlobalSoundSettingsContext = React.createContext({
    mute: false,
    setMute: noop,
    play: true,
    setPlay: noop,
    playing: false,
    setPlaying: noop,
})
GlobalSoundSettingsContext.displayName = 'GlobalSoundSettingsContext'

export const useGlobalSoundSettings = () => React.useContext(GlobalSoundSettingsContext)
export default GlobalSoundSettingsContext

export const GlobalSoundSettingsProvider = ({ children }) => {
    const [mute, setMute] = useState(false)
    const [play, setPlay] = useState(true)
    const [playing, setPlaying] = useState(false)

    const value = useMemo(() => ({
        mute,
        setMute,
        play,
        setPlay,
        playing,
        setPlaying,
    }), [mute, play, playing])

    return (
        <GlobalSoundSettingsContext.Provider value={value}>
            {children}
        </GlobalSoundSettingsContext.Provider>
    )
}
