import { useEffect, useRef } from 'react'
import { SPRITES, ViewsIndex } from '../../constants'
import { useSoundContext } from '../../context'
import { useCurrentView, useSleep } from '../../hooks'

export default function useAmbientSound() {
    const [, { sound }] = useSoundContext()
    const sleep = useSleep()
    const currentView = useCurrentView()
    const soundPlayId = useRef()

    useEffect(() => {
        if (sound) {
            switch (currentView.index) {
            case ViewsIndex.LandingView:
            case ViewsIndex.IntroView:
            case ViewsIndex.MainView:
            case ViewsIndex.OutroView:
            case ViewsIndex.EndView:
                soundPlayId.current = sound.play(SPRITES.main_menu_amb.id)
                sound.fade(0, 1, 2000, soundPlayId.current)
                break
            default:
                break
            }
        }

        return () => {
            if (sound && soundPlayId.current) {
                sound.fade(1, 0, 2000, soundPlayId.current)
                const soundIdToStop = soundPlayId.current
                sleep(2000).then(() => {
                    sound?.stop(soundIdToStop)
                })
            }
        }
    }, [sound, currentView])

    return null
}
