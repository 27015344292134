import { Views } from './views'

export const Topics = [{
    id: 'view1',
    index: 0,
    path: Views.DetailView0.path,
    label: Views.DetailView0.label,
    viewName: Views.DetailView0.name,
    isImplemented: false,
},
{
    id: 'view2',
    index: 1,
    path: Views.DetailView1.path,
    label: Views.DetailView1.label,
    viewName: Views.DetailView1.name,
    isImplemented: false,
},
{
    id: 'view3',
    index: 2,
    path: Views.DetailView2.path,
    label: Views.DetailView2.label,
    viewName: Views.DetailView2.name,
    isImplemented: false,
},
{
    id: 'view4',
    index: 3,
    path: Views.DetailView3.path,
    label: Views.DetailView3.label,
    viewName: Views.DetailView3.name,
    isImplemented: false,
},
{
    id: 'view5',
    index: 4,
    path: Views.DetailView4.path,
    label: Views.DetailView4.label,
    viewName: Views.DetailView4.name,
    isImplemented: false,
}]

export const finalAnalyticsTopic = {
    id: 'final',
    index: 5,
    path: Views.OutroView.path,
    label: 'See final analytics',
    viewName: Views.OutroView.name,
}
