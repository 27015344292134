import { useEffect, useRef } from 'react'
import { TIME_SCALE } from '../constants'

/**
 * returns a sleep function with auto clean up
 * @returns {(ms:number) => Promise}
 */
export default function useSleep() {
    const cleanUpTimeoutRefs = useRef([])

    const sleep = (milliseconds) => {
        if (milliseconds === 0) {
            return Promise.resolve()
        }
        return new Promise((resolve) => {
            const timeout = setTimeout(resolve, milliseconds / TIME_SCALE)
            cleanUpTimeoutRefs.current.push(timeout)
        })
    }

    useEffect(() => {
        return () => {
            for (let index = 0; index < cleanUpTimeoutRefs.current.length; index++) {
                const timeoutRef = cleanUpTimeoutRefs.current[index]
                clearTimeout(timeoutRef)
            }
        }
    }, [])

    return sleep
}
