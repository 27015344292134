import React, { useRef, useEffect } from 'react'
import ReactDom from 'react-dom'

const Portal = React.forwardRef(({ children, className = 'root-portal', el = 'div' }, ref) => {
    const container = useRef(document.createElement(el))
    ref.current = container.current

    useEffect(() => {
        className.split(' ').forEach(item => container.current.classList.add(item))
        document.body.appendChild(container.current)
        return () => {
            document.body.removeChild(container.current)
            container.current = null
            ref.current = null
        }
    }, [])

    return ReactDom.createPortal(children, container.current)
})

export default Portal
