import styled from 'styled-components'

export const Circle = styled.div`
  width: ${props => props.circleSize};
  height: ${props => props.circleSize};
  border: 1.5px solid rgba(211, 240, 240, 0.5);
  backdrop-filter: blur(4px);
  border-radius: 50%;
  cursor: pointer;
`
