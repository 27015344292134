import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { isMobileOnly, useMobileOrientation } from 'react-device-detect'
import FullScreen from '../components/FullScreen/FullScreen'
import Logo from '../components/Logo'
import withDelay from '../hoc/withDelay/withDelay'
import { SPRITES, Views } from '../constants'
import ButtonArrow from '../components/Buttons/ButtonArrow'
import { useSleep } from '../hooks'
import { isSmallScreenWidth, vp } from '../common/layout'
import ContactUs from '../components/ContactUs/ContactUs'
import runQualtrixIntercept from '../utils/runQualtrixIntercept'
import { useVoiceover } from '../context/VoiceOverContext'

const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    position:fixed;
    width: 100%;
    height: 23%;
    margin-left: 0; 
    bottom: 0;
    padding: 0.625rem;
    padding-left: 0;
    font-size: 0.9375rem;
    line-height: 1.4375rem;
    white-space: pre-line;    
    & .button-arrow {
        align-self: flex-start;
    }
    ${vp.small(`
        width:90%;
        margin-bottom:2rem;
        margin-left:0;
    `)}
    ${vp.medium(`
        margin-top: 1rem;
    `)}
    @media only screen and (max-height: 655px) and (max-width:1366px) {
        font-size: 0.7375rem;
        line-height: 1.2375rem;
        height: 25%;
    }
    @media only screen and (max-width:450px) {
        height: unset;
        margin-bottom: 0;
    }
`

const LinkContainerButtons = styled.div`
  margin-left: -1rem;
  display: flex;
  @media only screen and (max-width: 450px) {
    flex-direction: column;
    width: 100%;
    .magnetic-button, .magnetic-button--item, button {
      width: 100%;
    }
    > * {
      padding-top: 0;
    }
  }
`

const BackButtonContainer = styled.div`
    width: fit-content;
    height: fit-content;
    ${vp.large(`
        margin-bottom: 1.5rem;
    `)}
    ${vp.medium(`
        margin-bottom: 1rem;
    `)}
`

const StyledSpan = styled.span`
    font-family: '72 Bold';
    font-size: 2.8125rem;
    line-height: 3.6875rem;
    font-weight: normal;
    white-space: pre-line;
    margin:0;
    ${vp.small(`
        font-size: 1.375rem;
        line-height: 2.0625rem;
    `)}
    ${vp.medium(`
        font-size: 2.375rem;
        line-height: 3rem;
    `)}
`

const TextButton = styled.div`
    margin-bottom:1.5rem;
    font-size: 0.875rem;
    line-height: 1rem; 
    width: 100%;
    ${vp.large(`
        font-size: 1.25rem;
        line-height: 1.938rem; 
        margin-bottom: 0.8rem;

    `)}
     ${vp.medium(`
        font-size: 0.9rem;
        line-height: 1rem;
        margin-bottom: 0.8rem;
    `)}
    
      @media only screen and (max-height: 450px) and (max-width: 350px) {
        font-size: 0.8rem;
        line-height: 1.8rem;
        margin-bottom: 0;
    }
`

const StyledH2 = styled.h2`
    font-size: 2.8125rem;
    line-height: 3.6875rem;
    font-family:'72 Light';
    white-space: pre-line;
    max-width: 100vw; 
    margin:0;
    user-select: text;
    ${vp.small(`
        font-size: 1.375rem;
        line-height: 2.0625rem;
    `)}
    ${vp.medium(`
        font-size: 2.375rem;
        line-height: 3rem;
    `)}
`

const Content = styled.div`
    display: flex;
    padding-top: clamp(80px, 9%, 170px);
    padding-left: clamp(44px, 10%, 127px);
    height: 100vh;

    @media only screen and (max-width: 450px) {
        padding-top:50px;
    }

    .laptop {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }

    .left-side {
        min-width:max-content;
        justify-content: space-between;
        ${vp.medium(`
            margin-top:2rem;
        `)}
    }

    .right-side {
        flex: 1;
        padding-bottom: 2%;
        padding-right: 5%;
    }
    ${vp.small(`
        width:90%;
        font-size:1.375rem;
    `)}
    @media only screen and (max-width: 450px) {
        & ${BackButtonContainer} {
            transform: scale(0.85);
            transform-origin: left;
            > * {
                padding-top: 0;
                padding-bottom: 10px;
            }
            & button {
                height: 45px;
                width: 210px;
            }
        }
        & button {
            height: 40px;
        }
    }
`

const StyledLogo = styled(Logo)`
    margin:0;
    font-size:2.375rem;
    user-select: text;
    ${vp.small(`
        font-size: 1.375rem;
        line-height: 2.0625rem;
    `)}
`

const DelayContactUs = withDelay(ContactUs, Views.EndView.showInDelay + 4000)
const loadLaptopPng = lang => import(`../common/assets/Laptop_${lang}.png`)

const EndView = (props) => {
    const { className } = props
    const { t, i18n } = useTranslation()
    const [playVoiceover] = useVoiceover()
    const sleep = useSleep()
    const history = useHistory()
    const { isLandscape } = useMobileOrientation()
    const [dashboardImageUrl, setDashboardImageUrl] = useState(null)

    useEffect(() => {
        sleep(2000).then(() => {
            playVoiceover(SPRITES.vo_end_screen)
        })
    }, [playVoiceover])

    useEffect(() => {
        runQualtrixIntercept()
    }, [])

    const showRightSide = !isMobileOnly && !isSmallScreenWidth() && isLandscape
    const backButtonWidth = ['de-DE', 'es-ES', 'fr-FR'].includes(i18n.resolvedLanguage) ? 260 : 230

    useEffect(async () => {
        const fileUrl = await loadLaptopPng(i18n.resolvedLanguage)
        setDashboardImageUrl(fileUrl.default)
    }, [i18n.resolvedLanguage])

    return (
        <FullScreen className={className}>
            <DelayContactUs className="page-fade-enter" />
            <Content style={{ animationDuration: '1s', animationDelay: '2.5s' }} className="fade-enter">
                <div className="left-side">
                    <div>
                        <BackButtonContainer>
                            <ButtonArrow width={backButtonWidth} magnetic alignLeft isLefty outline className="button-arrow scale-in" onClick={() => history.push(Views.DashboardView.path, { from: 'endView' })} text={t('endScreen.backButton')} />
                        </BackButtonContainer>
                        <StyledH2>
                            <Trans
                                i18nKey="endScreen.header1"
                                components={{ logo: <StyledLogo />, bold: <StyledSpan /> }}
                            />
                        </StyledH2>
                    </div>
                    <LinkContainer>
                        <TextButton>{t('endScreen.linkHeader')}</TextButton>
                        <LinkContainerButtons>
                            <ButtonArrow className="button-arrow" isWhite width={210} text={t('endScreen.link1.button')} onClick={() => (window.open('https://www.sap.com/products/s4hana-erp.html', '_blank'))} />
                            <ButtonArrow className="button-arrow" isWhite width={210} text={t('endScreen.link2.button')} onClick={() => (window.open('https://www.sap.com/products/business-process-intelligence.html', '_blank'))} />
                        </LinkContainerButtons>
                    </LinkContainer>
                </div>
                {showRightSide && (
                    <div className="right-side">
                        <img src={dashboardImageUrl} alt="laptop" className="laptop" />
                    </div>
                )}
            </Content>
        </FullScreen>
    )
}

EndView.propTypes = {
    className: PropTypes.string,
}

EndView.defaultProps = {
    className: undefined,
}

export default withDelay(EndView, Views.EndView.showInDelay)
