const runQualtrixIntercept = () => {
    if (typeof QSI !== 'undefined') {
        // eslint-disable-next-line no-console
        window.QSI.API.run()
    } else {
        // eslint-disable-next-line no-console
        console.warn('QSI is not defined')
    }
}

export default runQualtrixIntercept
