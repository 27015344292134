/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/no-children-prop */
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ReactComponent as IconNext } from '../../common/assets/Icon-arrow.svg'
import { Circle } from '../../common/base'
import { vp } from '../../common/layout'
import MagneticButton from '../MagneticButton/MagneticButton'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(5px);
  position: fixed;
  right: 0;
  padding: 0 10px;
`

const Button = styled.div`
  word-break: keep-all;
  white-space: pre-line;
  text-align: center;
  padding-left: 10px;
  appearance: none;
`

const Line = styled.div`
  width: 1px;
  height: 40%;
  margin: 1rem 0;
  border-left: 1px dashed rgba(211, 240, 240, 0.5);
  box-sizing: border-box;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  align-self: flex-start;
  margin-left: calc((${props => props.circleSize} / 2) + 0.5rem );
  ${vp.small(`
  display: none;
  `)}
  ${vp.medium(`
  display: none;
  `)}
`

const StyledIconNext = styled(IconNext)`
  path {
      fill: white;
  }
`
const StyledCircle = styled(Circle)`
    background: rgba(0, 0, 0, 0.24);
    backdrop-filter: blur(5px);
`

const ContinueButton = ({ className, style, onClick, circleSize, children, magnetic }) => {
    const contentButton = (
        <div className="centerItem cursor-pointer">
            <StyledCircle className="centerItem" circleSize={circleSize} onClick={onClick}>
                <StyledIconNext />
            </StyledCircle>
            <Button onClick={onClick} type="button">
                {children}
            </Button>
        </div>
    )
    const button = magnetic ? (
        <MagneticButton
            tolerance={2}
            style={{ padding: '0.5rem' }}
            children={contentButton}
        />
    ) : contentButton

    return (
        <Wrapper style={style} className={className}>
            <div className="centerItem flex-col h-full">
                <Line circleSize={circleSize} />
                {button}
                <Line circleSize={circleSize} />
            </div>
        </Wrapper>
    )
}

ContinueButton.propTypes = {
    className: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    style: PropTypes.object,
    onClick: PropTypes.func,
    circleSize: PropTypes.string,
    magnetic: PropTypes.bool,
}

ContinueButton.defaultProps = {
    className: undefined,
    style: undefined,
    onClick: undefined,
    circleSize: '3rem',
    magnetic: false,
}

export default ContinueButton
