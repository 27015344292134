import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import Portal from '../../Portal'
import languages from '../../../translations/lang.json'

const StyledPortal = styled(Portal)`
    position: absolute;
`

const LangList = styled.div`
    background-color: #25355DDD;
    padding: 10px;
    border-radius: 5px;
    transform: translate(-50%, 0);
    
    > * {
        cursor: pointer;
        border-radius: 5px;
        padding: 10px;
        white-space: nowrap;
        :hover {
            font-weight: bold; 
        }
    }
    > *[data-selected="true"] {
        text-decoration: underline;
    }
`

function LanguageSwitch({ className }) {
    const [show, setShow] = useState(false)
    const { i18n } = useTranslation()
    const containerRef = useRef()
    const portalRef = useRef()
    const portalContainer = useRef(document.createElement('div'))
    useOnClickOutside(portalRef, () => setShow(false))

    const onClickContainer = (e) => {
        setShow(revValue => !revValue)
    }

    const onClickLanguage = (e) => {
        i18n.changeLanguage(e.target.getAttribute('data-value'))
        setShow(false)
    }

    useEffect(() => {
        if (show) {
            const list = (
                <StyledPortal ref={portalRef}>
                    <LangList>
                        {
                            Object.keys(i18n.options.resources).map((langCode) => {
                                const { local } = languages[langCode]
                                return (
                                    <div key={langCode} title={local} data-selected={i18n.resolvedLanguage === langCode} data-value={langCode} onClick={onClickLanguage}>{langCode.split('-')[0].toUpperCase()}</div>
                                )
                            })
                        }
                    </LangList>
                </StyledPortal>
            )

            ReactDOM.render(list, portalContainer.current, () => {
                if (portalRef.current) {
                    const containerClientRect = containerRef.current.getBoundingClientRect()
                    portalRef.current.style.left = (containerClientRect.left + containerClientRect.width / 2) + 'px'
                    portalRef.current.style.top = containerClientRect.bottom + 'px'
                }
            })
        }

        return () => {
            ReactDOM.unmountComponentAtNode(portalContainer.current)
        }
    }, [show])

    useEffect(() => {
        return () => {
            ReactDOM.unmountComponentAtNode(portalContainer.current)
        }
    }, [])

    return (
        <div ref={containerRef} className={`cursor-pointer centerItem  ${className || ''}`} onClick={onClickContainer}>
            {i18n.resolvedLanguage.split('-')[0].toUpperCase()}
        </div>
    )
}

export default React.memo(LanguageSwitch)
