import { useEffect, useRef } from 'react'
import gsap from 'gsap'

/**
 * returns a gsap timeline with auto clean up
 * @returns {gsap.core.Timeline}
 */
export default function useTimeline(paused = true) {
    const timeline = useRef(gsap.timeline({ paused: true }))

    useEffect(() => {
        return () => {
            timeline.current?.kill()
        }
    }, [])

    return timeline.current
}
