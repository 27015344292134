export const ViewsIndex = {
    LoadingView: 0,
    LandingView: 1,
    IntroView: 2,
    MainView: 3,
    DetailView0: 4,
    DetailView1: 5,
    DetailView2: 6,
    DetailView3: 7,
    DetailView4: 8,
    OutroView: 9,
    DashboardView: 10,
    AnniversaryView: 11,
    EndView: 12,
}

export const ShowDetailViewDelay = 3250
export const NextDetailViewDelay = 1

export const Views = {
    LoadingView: {
        index: ViewsIndex.LoadingView,
        name: 'LoadingView',
        path: null,
        showInDelay: 0,
    },
    LandingView: { index: ViewsIndex.LandingView, name: 'LandingView', path: '/', showInDelay: 0 },
    IntroView: { index: ViewsIndex.IntroView, name: 'IntroView', path: '/intro', sameViewForNestedRoutes: true, showInDelay: 0, detail: 'Intro' },
    MainView: { index: ViewsIndex.MainView, name: 'MainView', path: '/main', showInDelay: 0, detail: 'Main' },
    DetailView: { index: -1, name: 'DetailView', path: '/view', showInDelay: ShowDetailViewDelay },
    DetailView0: {
        index: ViewsIndex.DetailView0,
        name: 'DetailView0',
        path: '/view/1',
        showInDelay: ShowDetailViewDelay,
        detail: 'Finance',
    },
    DetailView1: {
        index: ViewsIndex.DetailView1,
        name: 'DetailView1',
        path: '/view/2',
        showInDelay: ShowDetailViewDelay,
        detail: 'Supply Chain',
    },
    DetailView2: {
        index: ViewsIndex.DetailView2,
        name: 'DetailView2',
        path: '/view/3',
        showInDelay: ShowDetailViewDelay,
        detail: 'Sales & Marketing',
    },
    DetailView3: {
        index: ViewsIndex.DetailView3,
        name: 'DetailView3',
        path: '/view/4',
        showInDelay: ShowDetailViewDelay,
        detail: 'Procurement',
    },
    DetailView4: {
        index: ViewsIndex.DetailView4,
        name: 'DetailView4',
        path: '/view/5',
        showInDelay: ShowDetailViewDelay,
        detail: 'Human Resources',
    },
    OutroView: { index: ViewsIndex.OutroView, name: 'OutroView', path: '/outro', showInDelay: 0, detail: 'Outro' },
    DashboardView: { index: ViewsIndex.DashboardView, name: 'DashboardView', path: '/dashboard', showInDelay: 0, detail: 'Dashboard' },
    AnniversaryView: { index: ViewsIndex.AnniversaryView, name: 'AnniversaryView', path: '/anniversary', showInDelay: 0, detail: 'Anniversary' },
    EndView: { index: ViewsIndex.EndView, name: 'EndView', path: '/end', showInDelay: 0, detail: 'End' },
}
