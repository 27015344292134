import { useEffect, useCallback, useRef } from 'react'
import useSound from 'use-sound'
import musicWebm from '../../common/assets/music.webm'
import musicMp3 from '../../common/assets/music.mp3'
import music from '../../common/assets/music.json'
import { SPRITES } from '../../constants'
import { useGlobalSoundSettings } from '../../context/GlobalSoundSettingsContext'

export default function useMusic() {
    const soundRef = useRef(null)
    const soundPlayIdRef = useRef(null)
    const { play, playing, setPlaying } = useGlobalSoundSettings()
    const playingRef = useRef(playing)

    playingRef.current = playing

    const onPlay = useCallback(() => {
        !playingRef.current && setPlaying(true)
    }, [])

    const onPause = useCallback(() => {
        playingRef.current && setPlaying(false)
    }, [])

    const [, { sound }] = useSound([musicWebm, musicMp3], {
        preload: true,
        sprite: music.sprite,
        onplay: onPlay,
        onpause: onPause,
    })

    soundRef.current = sound

    useEffect(() => {
        if (sound) {
            if (play) {
                soundPlayIdRef.current = sound.play(soundPlayIdRef.current || SPRITES.music.id)
            } else {
                soundPlayIdRef.current && sound.pause(soundPlayIdRef.current)
            }
        }
    }, [sound, play])

    useEffect(() => {
        return () => {
            soundRef.current?.unload()
            soundRef.current = null
        }
    }, [])

    return null
}
