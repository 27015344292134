import styled, { css } from 'styled-components'
import { colors } from './color'
import { vp } from './layout'

export const primaryFamily = ['72 Regular', 'sans-serif']
export const lightFamily = ['72 Light', 'sans-serif']
export const blackFamily = ['72 Black', 'sans-serif']

export const fontStyle = family => css`
  font-family: ${family.map((ff, i) => `'${ff}'${i < family.length - 1 ? ',' : ''}`)};
`

export const primaryFontStyle = () => css`
  ${fontStyle(primaryFamily)}
`
export const lightFontStyle = () => css`
  ${fontStyle(lightFamily)}
`
export const blackFontStyle = () => css`
  ${fontStyle(blackFamily)}
`

const headlineStyle = css`
  /* font-weight: bold; */
  font-family:'72 Bold';
`

export const textOutline = css`
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0.5px ${colors.font.strong};
`

export const H1 = styled.h1`
  ${lightFontStyle};
  letter-spacing: 2px;
  font-size: 80px;
  line-height: 80px;
  font-weight: lighter;
  /* text-transform: uppercase; */
  margin: 0;
`

export const H2 = styled.h2`
  ${headlineStyle};
  font-size: 24px;
  line-height: 38px;
  font-weight: bold;
  /* text-transform: uppercase; */
`

export const H3 = styled.h3`
  ${headlineStyle};
  font-size: 20px;
  line-height: 24px;
`

export const H3SoundLayer = styled.h3`
  ${headlineStyle};
  font-size: 18px;
  line-height: 24px;
`

export const H4 = styled.h4`
  ${headlineStyle};
  font-size: 14px;
`

export const H5 = styled.h5`
  ${headlineStyle};
  font-size: 16px;
  line-height: 20px;
`

export const fontSize = '16px'

export const textStyle = css`
  h1, h2, h3, h4, h5, h6 {
    ${blackFontStyle}
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0.525px;
  }

  h1 {
    font-size: 4.5rem; // 72px
  }
  h2 {
    font-size: 3.75rem; // 60px
  }
  h3 {
    font-size: 2.313rem; // 37px
    line-height: 2.563rem; // 41px
  }
  h4 {
    font-size: 2rem; // 32px
  }
  h5 {
    font-size: 1.875rem; // 30 px
  }
  h6 {
    ${primaryFontStyle}
    letter-spacing: 0.52px;
    font-size: 1.5rem; // 24px
    margin-block-start: 0.6em;
    margin-block-end: 0.6em;
  }

  ${vp.small(`
  h1 {
    font-size: 2.875rem; // 46px
  }
  h2 {
    font-size: 2.5rem; // 40px
  }
  h3 {
    font-size: 2rem; // 32px
    line-height: 2.125rem; // 34px
  }
  h4 {
    font-size: 1.875rem; // 30 px
  }
  h5 {
    font-size: 1.5rem; // 24px
  }
  h6 {
    font-size: 1.125rem; // 18px
  }
  `)}
`

export const textOverflowEllipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const HeadLine = styled.h2`
  font-family: '72 Light','sans-serif';
  font-style: normal;
  font-weight: normal;
  font-size: 2.188rem;
  line-height: 3rem;
  text-align: center;
  white-space: pre-line;
  ${vp.small(`
  font-size: 1.5rem;
  line-height: 2rem;
  `)}
`

export const Text = styled.span`
  font-size: 1rem;
  line-height: 1.25rem;
`
