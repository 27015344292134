import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import useSound from 'use-sound'
import soundWebm from '../common/assets/sound.webm'
import soundMp3 from '../common/assets/sound.mp3'
import sound from '../common/assets/sound.json'

const spriteNames = Object.keys(sound.sprite)
// set loop flag for sprites
for (let index = 0; index < spriteNames.length; index++) {
    const name = spriteNames[index]
    if ((name.endsWith('-amb') || name.endsWith('music') || name === 'dashboard-count') && sound.sprite[name].length === 2) {
        sound.sprite[name].push(true)
    }
}

/**
 * @typedef {Object} PlayController
 * @property {Object} sound Howl Object.
 * @property {(id?: string) => void} stop
 * @property {(id?: string) => void} pause
 * @property {number} duration
 */

/**
 * @type {PlayController}
 */
const initPlayController = null

const defaultSoundContext = [
    initPlayController,
    /**
    * play function
    * @param {{id: string, forceSoundEnabled: boolean, playbackRate: number}} playOptions
    */
    (options) => {},
]

const SoundContext = React.createContext(defaultSoundContext)
SoundContext.displayName = 'SoundContext'

export const useSoundContext = () => React.useContext(SoundContext)
export default SoundContext

export const SoundProvider = ({ children }) => {
    const playRef = useRef(null)
    const howlerRef = useRef(null)

    const useSoundObject = useSound([soundWebm, soundMp3], {
        preload: true,
        sprite: sound.sprite,
    })

    const [playInternal, {
        sound: howlerObj,
        stop,
        pause,
        duration,
    }] = useSoundObject

    playRef.current = playInternal
    howlerRef.current = howlerObj

    const playController = useMemo(() => ({
        sound: howlerObj,
        stop,
        pause,
        duration,
    }), [howlerObj, stop, pause, duration])

    const play = useCallback((...args) => {
        playRef.current && playRef.current(...args)
    }, [])

    useEffect(() => {
        return () => {
            /**
            * Stop, unload and destroy howler object
            */
            howlerRef.current?.off() // Remove event listener
            howlerRef.current?.stop() // Stop playback
            howlerRef.current?.unload() // Remove sound from pool
            howlerRef.current = null
        }
    }, [])

    const value = useMemo(() => ([play, playController]), [playController])

    return (
        <SoundContext.Provider value={value}>
            {children}
        </SoundContext.Provider>
    )
}
