import React from 'react'
import { Route, Switch, useLocation, Redirect } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { isMobile } from 'react-device-detect'
import LandingView from './LandingView'
import { IS_DEV_MODE, PAGE_TRANSITION_TIMEOUT, Views } from '../constants'
import TopControlPanel from '../components/TopControlPanel'
import FullScreen from '../components/FullScreen/FullScreen'
import SyncRouter from '../components/SyncRouter/SyncRouter'
import { hasSmallScreenWidth } from '../common/layout'
import { MenuProvider } from '../context/MenuContext'
import MainView from './MainView'
import EndView from './EndView'
import AnniversaryView from './AnniversaryView'

import Metrics from '../components/Metrics'
import Sound from '../components/Sound'
import SAPLogo from '../components/Logo/SAPLogo'
import FpsMonitor from '../components/FpsMonitor/FpsMonitor'
import ScreenOrientationLock from '../components/ScreenOrientationLock'

const WebGL = React.lazy(() => import('../components/WebGL').then((result) => {
    document.querySelector('.preloader')?.remove()
    return result
}))
const Outro = React.lazy(() => import('./Outro'))
const Intro = React.lazy(() => import('./Intro'))
const DetailViewBase = React.lazy(() => import('./DetailViewBase'))
const DashboardView = React.lazy(() => import('./DashboardView'))
const getMainMenu = useMobile => useMobile ? React.lazy(() => import('../components/MainMenu/MainMenuMobile')) : React.lazy(() => import('../components/MainMenu/MainMenu'))

const DynamicMainMenu = getMainMenu(isMobile || hasSmallScreenWidth)

function Start() {
    const location = useLocation()
    const showMainMenu = Views.MainView.path === location.pathname

    return (
        <>
            <SyncRouter />
            <Metrics />
            <ScreenOrientationLock />
            {IS_DEV_MODE && <FpsMonitor />}
            <Sound />
            <div id="headerElements">
                <TopControlPanel />
                <SAPLogo />
            </div>
            <TransitionGroup
                childFactory={child => React.cloneElement(child, {
                    classNames: location?.state?.transition || 'page-fade',
                    timeout: location?.state?.duration || PAGE_TRANSITION_TIMEOUT,
                })}
                component={null}
            >
                <CSSTransition
                    timeout={PAGE_TRANSITION_TIMEOUT}
                    classNames="page-fade"
                    key={location.key || location.pathname + location.search}
                >
                    <FullScreen animationDuration={location?.state?.duration} animationDelay={location?.state?.delay}>
                        <Switch location={location}>
                            <Route exact path={[Views.IntroView.path + '/:step', Views.IntroView.path]}>
                                <React.Suspense fallback={null}>
                                    <Intro />
                                </React.Suspense>
                            </Route>
                            <Route exact path={[Views.OutroView.path]}>
                                <React.Suspense fallback={null}>
                                    <Outro />
                                </React.Suspense>
                            </Route>
                            <Route exact path={[Views.DashboardView.path]}>
                                <React.Suspense fallback={null}>
                                    <DashboardView />
                                </React.Suspense>
                            </Route>
                            <Route exact path={[Views.AnniversaryView.path]}>
                                <React.Suspense fallback={null}>
                                    <AnniversaryView />
                                </React.Suspense>
                            </Route>
                            <Route strict path={Views.DetailView.path}>
                                <React.Suspense fallback={null}>
                                    <DetailViewBase />
                                </React.Suspense>
                            </Route>
                            <Route exact path={Views.MainView.path}>
                                <React.Suspense fallback={null}>
                                    <MainView />
                                </React.Suspense>
                            </Route>
                            <Route exact path={Views.EndView.path}>
                                <React.Suspense fallback={null}>
                                    <EndView />
                                </React.Suspense>
                            </Route>
                            <Route exact path={Views.LandingView.path} component={LandingView} />
                            <Route path="*">
                                <Redirect to="/" />
                            </Route>
                        </Switch>
                    </FullScreen>
                </CSSTransition>
            </TransitionGroup>
            <MenuProvider>
                <React.Suspense fallback={null}>
                    <DynamicMainMenu show={showMainMenu} />
                    <WebGL />
                </React.Suspense>
            </MenuProvider>
            <div id="modalEl" />
            <div id="tweakpane" />
        </>
    )
}

export default Start
