import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import en from './translations/en.json'
// import de_DE from './translations/de-DE.json'
// import es_ES from './translations/es-ES.json'
import es_CO from './translations/es-CO.json'
// import fr_FR from './translations/fr-FR.json'
// import pt_BR from './translations/pt-BR.json'
// import zh_CN from './translations/zh-CN.json'
// import ja_JP from './translations/ja-JP.json'

const isDevelopmentEnv = process.env.NODE_ENV === 'development'

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: isDevelopmentEnv,
        fallbackLng: 'en-EN',
        interpolation: { escapeValue: false }, // React already does escaping
        resources: {
            'en-EN': { translation: en },
            // 'de-DE': { translation: de_DE },
            // 'es-ES': { translation: es_ES },
            'es-CO': { translation: es_CO },
            // 'fr-FR': { translation: fr_FR },
            // 'pt-BR': { translation: pt_BR },
            // 'zh-CN': { translation: zh_CN },
            // 'ja-JP': { translation: ja_JP },
        },
    })

export default i18n
