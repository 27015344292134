import fscreen from './fscreen'

const handleFullScreen = () => {
    if (!document.fullscreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
        const element = document.documentElement
        fscreen.requestFullscreen(element)
    } else {
        fscreen.exitFullscreen()
    }
}
export default handleFullScreen
