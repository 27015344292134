import styled from 'styled-components'
import { vp } from '../../common/layout'
import { H2 } from '../../common/text'

const StyledSymbol = styled.span`
    font-size: 1.2rem;
    line-height: 1rem;
    position: relative;
    user-select: none;
    top: -18px;
    left: -1ch;
    ${vp.small(`
     font-size: 0.375rem;
    line-height: 1.0625rem;
    top: -11px;
    left: -8px;
    `)}
     ${vp.medium(`
    font-size: 1rem;
    `)}

`
const StyledH2 = styled(H2)`
    font-family: '72 Bold';
    font-size: 2.8125rem;
    line-height: 3.6875rem;
    font-weight: normal;
    white-space: pre-line;
    margin:0;
    user-select: text;
    ${vp.small(`
     font-size: 1.375rem;
    line-height: 2.0625rem;
    `)}
    ${vp.medium(`
    font-size: 2.375rem;
    line-height: 3rem;
    `)}

`

export default function Logo({ className }) {
    return (
        <StyledH2>
            <span>SAP S/4HANA&nbsp;</span>
            <StyledSymbol>®</StyledSymbol>
            <span>Cloud</span>
        </StyledH2>
    )
}
