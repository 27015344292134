import { ViewsIndex } from './views'
import sound from '../common/assets/sound.json'
import voiceover from '../common/assets/voiceover.json'

export const SPRITES = {
    music: { id: 'music' },
    snap_on: { id: 'snap-on' },
    snap_off: { id: 'snap-off' },
    select: { id: 'select' },
    hover_anim_on: { id: 'hover-anim-on' },
    hover_anim_off: { id: 'hover-anim-off' },
    zoom_in: { id: 'zoom-in' },
    zoom_out: { id: 'zoom-out' },
    muscle_pain: { id: 'muscle-pain' },

    //Bkgd Ambience
    main_menu_amb: { id: 'main-menu-amb' },
    sales_marketing_amb: { id: 'sales-marketing-amb' },
    supply_chain_amb1: { id: 'supply-chain-amb1' },
    supply_chain_amb2: { id: 'supply-chain-amb2' },
    finances_amb: { id: 'finances-amb' },
    human_resources_amb: { id: 'human-resources-amb' },

    // Detail View Shared
    analyzing_popup: { id: 'analyzing-popup' },
    analyzing: { id: 'analyzing' },
    analyzing_done: { id: 'analyzing-done' },
    analyzing_pt2: { id: 'analyzing-pt2' },
    circular_bar_fade_in: { id: 'circular-bar-fade-in' },
    value_drivers_show1: { id: 'value-drivers-show1' },
    value_drivers_show2: { id: 'value-drivers-show2' },
    value_drivers_show3: { id: 'value-drivers-show3' },
    value_drivers_update1: { id: 'value-drivers-update1' },
    value_drivers_update2: { id: 'value-drivers-update2' },
    value_drivers_update3: { id: 'value-drivers-update3' },
    value_driver_update_ticker: { id: 'value-drivers-update-ticker' },
    circular_bar_update: { id: 'circular-bar-update' },
    circular_bar_move_left: { id: 'circular-bar-move-left' },
    circular_bar_move_right: { id: 'circular-bar-move-right' },
    processing_simulation: { id: 'processing-simulation' },

    // LOB Finance
    finance_part1: { id: 'finance-part1' },
    finance_part2: { id: 'finance-part2' },
    finance_part3: { id: 'finance-part3' },

    // LOB Supply Chain
    supply_chain_part1: { id: 'supply-chain-part1' },
    supply_chain_part2: { id: 'supply-chain-part2' },
    supply_chain_part3: { id: 'supply-chain-part3' },

    // LOB Sales & Marketing
    sales_marketing_part1: { id: 'sales-marketing-part1' },
    sales_marketing_part2: { id: 'sales-marketing-part2' },
    sales_marketing_part3: { id: 'sales-marketing-part3' },
    sales_marketing_part2_extras: { id: 'sales-marketing-part2-tweet' },

    // LOB Procurement
    procurement_part1: { id: 'procurement-part1' },
    procurement_part3: { id: 'procurement-part3' },

    // LOB Human Resources
    human_resources_part1: { id: 'human-resources-part1' },
    human_resources_part2: { id: 'human-resources-part2' },
    human_resources_part3: { id: 'human-resources-part3' },

    // Transition to Dashboard
    outro_pt1: { id: 'outro-pt1' },
    anniversary: { id: 'anniversary' },
    anniversary_whoosh_out: { id: 'anniversary-whoosh-out' },

    // Dashboard
    dashboard_hover: { id: 'dashboard-hover' },
    dashboard_count: { id: 'dashboard-count' },
    dashboard_notification: { id: 'dashboard-notification' },
    dashboard_circular_bars: { id: 'dashboard-circular-bars' },

    // ---- VOICE OVER ----
    // Intro
    vo_intro: { id: 'vo-intro' },

    // Main View
    vo_main_menu: { id: 'vo-main-menu' },

    // Detail View Start (THE ISSUE)
    vo_sales_marketing_issue: { id: 'vo-sales-marketing-issue' },
    vo_supply_chain_issue: { id: 'vo-supply-chain-issue' },
    vo_procurement_issue: { id: 'vo-procurement-issue' },
    vo_finances_issue: { id: 'vo-finances-issue' },
    vo_human_resources_issue: { id: 'vo-human-resources-issue' },

    // Detail View Seq. 1 Finished (THE SOLUTION)
    vo_sales_marketing_solution: { id: 'vo-sales-marketing-solution' },
    vo_supply_chain_solution: { id: 'vo-supply-chain-solution' },
    vo_procurement_solution: { id: 'vo-procurement-solution' },
    vo_finances_solution: { id: 'vo-finances-solution' },
    vo_human_resources_solution: { id: 'vo-human-resources-solution' },

    // Detail View Finished
    vo_process_improved: { id: 'vo-process-improved' },
    vo_next_move: { id: 'vo-next-move' },

    vo_value_driver_improvement: { id: 'vo-value-driver-improvement' },

    // Outro
    vo_outro: { id: 'vo-outro' },
    vo_anniversary: { id: 'vo-anniversary' },

    // End Screen
    vo_end_screen: { id: 'vo-end-screen' },
}

export const getSpriteDuration = (sprite) => {
    const spriteData = sound.sprite[sprite?.id ?? sprite] ?? voiceover.sprite[sprite?.id ?? sprite]
    if (spriteData) {
        return spriteData[1]
    }
    return 0
}

export const getLobVoiceOverSprite = (viewIndex, solution) => {
    const type = solution ? 'solution' : 'issue'

    switch (viewIndex) {
    case ViewsIndex.DetailView0:
        return SPRITES['vo_finances_' + type]
    case ViewsIndex.DetailView1:
        return SPRITES['vo_supply_chain_' + type]
    case ViewsIndex.DetailView2:
        return SPRITES['vo_sales_marketing_' + type]
    case ViewsIndex.DetailView3:
        return SPRITES['vo_procurement_' + type]
    case ViewsIndex.DetailView4:
        return SPRITES['vo_human_resources_' + type]
    default:
        return null
    }
}

export const getLobBackgroundAmbienceSprite = (viewIndex) => {
    switch (viewIndex) {
    case ViewsIndex.DetailView0:
        return SPRITES.finances_amb
    case ViewsIndex.DetailView1:
        return SPRITES.supply_chain_amb1
    case ViewsIndex.DetailView2:
        return SPRITES.sales_marketing_amb
    case ViewsIndex.DetailView3:
        return SPRITES.human_resources_amb // Since this is also an office scene now, I think we can just use the human resources ambience here
    case ViewsIndex.DetailView4:
        return SPRITES.human_resources_amb
    default:
        return null
    }
}

export const getLobSoundSprite = (viewIndex, part) => {
    const partString = 'part' + part

    switch (viewIndex) {
    case ViewsIndex.DetailView0:
        return SPRITES['finance_' + partString]
    case ViewsIndex.DetailView1:
        return SPRITES['supply_chain_' + partString]
    case ViewsIndex.DetailView2:
        return SPRITES['sales_marketing_' + partString]
    case ViewsIndex.DetailView3:
        return SPRITES['procurement_' + partString]
    case ViewsIndex.DetailView4:
        return SPRITES['human_resources_' + partString]
    default:
        return null
    }
}
