import { useMemo } from 'react'
import { isMobile } from 'react-device-detect'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { hasSmallScreenWidth, vp } from '../common/layout'
import { PAGE_TRANSITION_TIMEOUT, Views } from '../constants'
import { useGlobalContext } from '../context'
import withDelay from '../hoc/withDelay/withDelay'
import ContinueButton from '../components/ContinueButton/ContinueButton'

const HeaderLineContainer = styled.div`
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items:flex-start;
    margin-top:3rem;
    opacity: ${props => props.show || props.show === undefined ? 1 : 0};
    transition: opacity 500ms;
    @media only screen and (max-height: 450px) and (max-width: 350px) {
    margin-top: 1rem;
    font-size: 0.5rem;    
    }
`

const HeaderLine = styled.h1`
    font-family: '72 Light';
    white-space: pre-line;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align:center;
    margin: 35px 20px;

    @media only screen and (max-height: 450px) and (max-width: 370px) {
    font-size: 1.4rem;
    white-space: normal;    
    }

    @media only screen and  (min-width: 300px) and (max-width: 370px) {
    font-size: 1.2rem;
    white-space: normal;    
    }

${vp.medium(css`
    font-size: 2rem;
    white-space: pre-line;
`)}

${vp.large(css`
    white-space: initial;
    font-size: 2.1875rem;
    line-height: 3.0625rem;
    margin: 0;
`)}
    @media only screen and  (min-width: 1366px) and (max-width: 1560px) {
    white-space: pre-line;    
    }
`

export const showMobileMainMenu = isMobile || hasSmallScreenWidth

const Main = () => {
    const history = useHistory()
    const { t } = useTranslation()
    const { state } = useGlobalContext()

    const showContinueButton = useMemo(() => {
        return Object.entries(state.viewData).reduce((sum, currentItem) => {
            return currentItem[0].startsWith('DetailView') && currentItem[1].isImplemented ? sum + 1 : sum
        }, 0) >= 2
    }, [state.viewData])

    const onClick = () => {
        history.push(Views.OutroView.path)
    }

    return (
        <>
            <HeaderLineContainer show={history.location.pathname === Views.MainView.path}>
                <HeaderLine>{t('mainView.header')}</HeaderLine>
            </HeaderLineContainer>
            {!showMobileMainMenu && (
                <div className="flex h-full">
                    { showContinueButton && <ContinueButton style={{ animationDelay: `${PAGE_TRANSITION_TIMEOUT}ms` }} className="page-fade-enter" magnetic onClick={onClick}>{t('mainView.continueButton')}</ContinueButton> }
                </div>
            )}
        </>
    )
}

export default withDelay(Main, Views.MainView.showInDelay)
