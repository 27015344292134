import { css } from 'styled-components'

export const theme = {
    breakpoints: {
        sm: '460px',
        md: '769px',
        lg: '960px',
        xl: '1440px',
    },
}

export const breakPoints = {
    extraSmall: { min: 0, max: 279 },
    small: { min: 280, max: 767 },
    medium: { min: 768, max: 1365 },
    large: { min: 1366, max: 99999 },
    mobile: { portrait: 768, landscape: 1024 },
}

export const isLandscape = () => window.matchMedia('only screen and (orientation:landscape)').matches
export const isPortrait = () => window.matchMedia('only screen and (orientation:portrait)').matches
export const isMaxScreenWidth1080 = () => window.matchMedia('only screen and (max-width: 1080px) and (orientation:portrait)').matches

export const isMobile = () => {
    return isLandscape()
        ? window.matchMedia('only screen and (max-height: ' + breakPoints.mobile.portrait + 'px)').matches && window.matchMedia('only screen and (max-width: ' + breakPoints.mobile.landscape + 'px)').matches
        : window.matchMedia('only screen and (max-width: ' + breakPoints.mobile.portrait + 'px)').matches
}

export const isSmallScreenWidth = () => window.matchMedia('only screen and (max-width: 768px)').matches
export const hasSmallScreenWidth = isSmallScreenWidth()

export const mobileCss = (style) => {
    return isMobile() ? css`${style}` : ''
}

export const mediaMin = (breakpoint, style) => css`
  @media only screen and (min-width: ${breakPoints[breakpoint].min}px) {
    ${style}
  }
`

export const mediaMax = (breakpoint, style) => css`
  @media only screen and (max-width: ${breakPoints[breakpoint].max}px) {
    ${style}
  }
`

export const mediaMaxPx = (pixel, style) => css`
  @media only screen and (max-width: ${pixel}px) {
    ${style}
  }
`

export const mediaMinMax = (breakpointMin, breakpointMax, style) => css`
  ${mediaMin(breakpointMin, mediaMax(breakpointMax, style))}
`

const extraSmall = style => css`
  ${mediaMinMax('extraSmall', 'extraSmall', style)}
`

const small = style => css`
  ${mediaMinMax('small', 'small', style)}
`

const medium = style => css`
  ${mediaMinMax('medium', 'medium', style)}
`

const large = style => css`
  ${mediaMinMax('large', 'large', style)}
`

export const styledScrollbar = css`
  // => firefox
  scrollbar-width: thin;
  scrollbar-color: #444444 #f7f9fb;

  // => webkit
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #444444;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #737373;
  }
`

export const vp = {
    extraSmall,
    small,
    medium,
    large,
    isMobile,
    mobileCss,
}
