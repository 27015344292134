import styled from 'styled-components'

const FullScreen = styled.div`
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: absolute;
    animation-duration: ${({ animationDuration }) => animationDuration ? animationDuration + 'ms' : 'unset'};
    animation-delay: ${({ animationDelay }) => animationDelay ? animationDelay + 'ms' : 'unset'};
`
export default FullScreen
