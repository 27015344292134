import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalContext } from '../../context'
import useCurrentView from '../../hooks/useCurrentView'

function SyncRouter() {
    const currentView = useCurrentView()
    const globalContext = useGlobalContext()
    const { t } = useTranslation()
    const baseTitle = currentView?.index === 1 ? 'SAP S4/HANA Cloud' : 'SAP S4/HANA Cloud | '

    useEffect(() => {
        globalContext.setViewIndex(currentView?.index || -1)
        document.title = baseTitle + (currentView?.name ? t(`titles.${currentView?.name}`) : '')
    }, [currentView])

    return null
}

SyncRouter.propTypes = {}

export default SyncRouter
