import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

/**
* play function
* @param {string | {id: string}} sprite sprite name oder object with id
* @returns {number} play id
*/
const noopPlay = (sprite) => {}
/**
* stop playing
* @param {null | string | {id: string}} sprite sprite name oder object with id
*/
const noopStop = (sprite) => {}

const VoiceoverContext = React.createContext([noopPlay, noopStop])
VoiceoverContext.displayName = 'VoiceoverContext'

export const useVoiceover = () => React.useContext(VoiceoverContext)
export default VoiceoverContext

export const VoiceoverProvider = ({ children }) => {
    const howlerRef = useRef(null)
    const playingIds = useRef({})
    const { i18n } = useTranslation()

    const removePlayingId = (idToRemove) => {
        Object.keys(playingIds.current).forEach((key) => {
            playingIds.current[key] = playingIds.current[key].filter(id => id !== idToRemove)
        })
    }

    const [sound, setSound] = useState()

    useEffect(() => {
        const cleanUp = () => {
            /**
            * Stop, unload and destroy howler object
            */
            howlerRef.current?.off() // Remove event listener
            howlerRef.current?.stop() // Stop playback
            howlerRef.current?.unload() // Remove sound from pool
            howlerRef.current = null
        }

        const updateSoundFile = (newLang) => {
            const lang = newLang === 'en-EN' ? '' : `_${newLang}`
            Promise.all([
                import(`../common/assets/voiceover${lang}.webm`),
                import(`../common/assets/voiceover${lang}.mp3`),
                import(`../common/assets/voiceover${lang}.json`),
            ]).then(([soundWebm, soundMp3, newSoundJson]) => {
                cleanUp()

                const howlChecker = setInterval(() => {
                    if (window.Howl) {
                        clearInterval(howlChecker)
                        howlerRef.current = new window.Howl({
                            src: [soundWebm.default, soundMp3.default],
                            preload: true,
                            sprite: newSoundJson.sprite,
                            interrupt: true,
                            onstop: stoppedId => removePlayingId(stoppedId),
                            onend: endId => removePlayingId(endId),
                        })
                        setSound(howlerRef.current)
                    }
                }, 100)
            }).catch(() => updateSoundFile('en-EN'))
        }

        updateSoundFile(i18n.resolvedLanguage)
        i18n.on('languageChanged', updateSoundFile)

        return () => {
            i18n.off('languageChanged', updateSoundFile)
            cleanUp()
        }
    }, [])

    const playVoiceover = useCallback((sprite) => {
        if (howlerRef.current) {
            howlerRef.current.stop()
            const spriteId = sprite?.id ?? sprite
            const playId = howlerRef.current.play(spriteId)
            if (playingIds.current[spriteId]) {
                playingIds.current[spriteId].push(playId)
            } else {
                playingIds.current[spriteId] = [playId]
            }
            return playId
        }
        return 0
    }, [])

    const stopVoiceover = useCallback((sprite) => { // sprite or sound id
        if (howlerRef.current) {
            let idToStop = null
            if (sprite) {
                if (typeof sprite === 'number') {
                    idToStop = sprite
                    removePlayingId(idToStop)
                } else {
                    const spriteId = sprite?.id ?? sprite
                    idToStop = playingIds.current[spriteId]?.pop()
                }
            }
            return howlerRef.current.stop(idToStop)
        }
        return 0
    }, [])

    const value = useMemo(() => [playVoiceover, stopVoiceover, sound != null, sound], [playVoiceover, stopVoiceover, sound])

    return (
        <VoiceoverContext.Provider value={value}>
            {children}
        </VoiceoverContext.Provider>
    )
}
