import { useCallback } from 'react'
import useCurrentView from './useCurrentView'
import { useGlobalContext } from '../context/GlobalContext/GlobalContext'
import { getViewByIndexOrName } from '../context/GlobalContext/globalReducer'

/**
 * returns the view data object from global context
 * @param {number|string} viewIndex index or view name
 * @returns {Object}
 */
export function useViewData(viewIndex) {
    const globalContext = useGlobalContext()
    const currentView = getViewByIndexOrName(viewIndex)

    return globalContext.state.viewData[currentView?.name] || { }
}

/**
 * returns the current view data object from global context based on the current location
 * @returns [{Object}, (data) => {}]
 */
export function useCurrentViewData() {
    const globalContext = useGlobalContext()
    const currentView = useCurrentView()

    const updateViewData = useCallback(
        (data) => {
            globalContext.updateViewData(currentView.index, data)
        },
        [currentView?.index],
    )

    const viewData = globalContext.state.viewData[currentView?.name] || { }

    return [viewData, updateViewData]
}
