import { Views, DETAIL_VIEW_STATE } from '../../constants'

const getValueDriver = (index, value, translateX = 0, translateY = 0) => {
    return {
        VdData: value + '%',
        isVisible: false,
        isActive: false,
        graph: index + 1,
        translate: [translateX, translateY],
    }
}

export const initGlobalState = {
    viewIndex: 0,
    viewData: {
        IntroView: { stepIndex: 0 },
        DetailView0: { index: 0, isImplemented: false, state: DETAIL_VIEW_STATE.none, issueValue: 30, successValue: 85, valueDrivers: [getValueDriver(0, '-50', 6, 70), getValueDriver(1, '+20', 49, 22), getValueDriver(2, '-50', 44, 92)] },
        DetailView1: { index: 1, isImplemented: false, state: DETAIL_VIEW_STATE.none, issueValue: 40, successValue: 75, valueDrivers: [getValueDriver(0, '+30', 10, 81), getValueDriver(1, '-8', 26, 28), getValueDriver(2, '-20', 37, 80)] },
        DetailView2: { index: 2, isImplemented: false, state: DETAIL_VIEW_STATE.none, issueValue: 25, successValue: 80, valueDrivers: [getValueDriver(0, '+20', 10, 101), getValueDriver(1, '+40', -6, 26), getValueDriver(2, '+30', -10, 100)] },
        DetailView3: { index: 3, isImplemented: false, state: DETAIL_VIEW_STATE.none, issueValue: 35, successValue: 70, valueDrivers: [getValueDriver(0, '+40', 6, 80), getValueDriver(1, '-30', 38.5, 37), getValueDriver(2, '+15', 48, 98)] },
        DetailView4: { index: 4, isImplemented: false, state: DETAIL_VIEW_STATE.none, issueValue: 45, successValue: 80, valueDrivers: [getValueDriver(0, '+25', 6, 80), getValueDriver(1, '+20', 23, 32), getValueDriver(2, '+30', 33, 95)] },
        OutroView: { stepIndex: 0 },
    },
}

export const UPDATE_VIEW_DATA = 'APP/GLOBAL/UPDATE_VIEW_DATA'
export const SET_VIEW_INDEX_STATE = 'APP/GLOBAL/SET_VIEW_INDEX_STATE'
export const UPDATE_VALUE_DRIVER = 'APP/GLOBAL/UPDATE_VALUE_DRIVER'
export const SET_VALUE_DRIVER_COORDINATES = 'APP/GLOBAL/SET_VALUE_DRIVER_COORDINATES'

export const getViewByIndexOrName = (index) => {
    if (Number.isInteger(index)) {
        return Object.values(Views).find(view => view.index === index)
    }
    return Views[index]
}

export const globalReducer = (state = initGlobalState, action) => {
    switch (action.type) {
    case UPDATE_VIEW_DATA: {
        const { index, data } = action
        const name = getViewByIndexOrName(index).name

        const viewData = state.viewData
        const currentData = viewData[name] || {}
        const newData = { ...currentData, ...data }

        return {
            ...state,
            viewData: {
                ...viewData,
                [name]: newData,
            },
        }
    }
    case UPDATE_VALUE_DRIVER: {
        const { index: indexToUpdate, data } = action
        const name = getViewByIndexOrName(state.viewIndex).name

        const viewData = state.viewData
        const currentData = viewData[name]
        if (!currentData) return state

        const valueDrivers = currentData.valueDrivers.map((oldValueDriver, index) => {
            if (index === indexToUpdate) {
                return {
                    ...oldValueDriver,
                    ...data,
                }
            }
            return oldValueDriver
        })

        viewData[name] = {
            ...currentData,
            valueDrivers,
        }

        return {
            ...state,
            viewData,
        }
    }
    case SET_VALUE_DRIVER_COORDINATES: {
        const { valueDriverCoordinates } = action
        const name = getViewByIndexOrName(state.viewIndex).name

        const viewData = state.viewData
        const currentData = viewData[name]

        if (!currentData) return state

        const valueDrivers = currentData.valueDrivers.map((oldValueDriver, index) => {
            return {
                ...oldValueDriver,
                position: { ...valueDriverCoordinates[index] },
            }
        })

        viewData[name] = { ...currentData, valueDrivers }

        return {
            ...state,
            viewData,
        }
    }
    case SET_VIEW_INDEX_STATE: {
        if (state.viewIndex !== action.index) {
            return {
                ...state,
                viewIndex: action.index,
            }
        }
        return state
    }
    default:
        throw new Error(`Unhandled action type: ${action.type}`)
    }
}
