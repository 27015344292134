import styled from 'styled-components'
import { ReactComponent as Logo } from '../../common/assets/sap_logo.svg'
import { vp } from '../../common/layout'

const Uri = process.env.PUBLIC_URL || '/'

const StyledLogo = styled(Logo)`
position: fixed;
margin: 2.5rem 3.125rem;
cursor: pointer;
z-index:1000;
transition: all 0.2s ease-in-out;
&:hover{
transform: scale(1.05)
}
${vp.small(`
margin: 5px -5px;
transform: scale(0.6);
&:hover{
transform: scale(0.75)
}
`)}

@media only screen and (min-width: 768px) and (max-width: 1365px) and (orientation: portrait) {
    margin: 2rem 0.5rem;
    transform: scale(0.9);
    &:hover{
        transform: scale(1)
    }
}

`
const SAPLogo = () => {
    return (
        <a href={Uri}>
            <StyledLogo />
        </a>
    )
}

export default SAPLogo
