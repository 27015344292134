import React, { useEffect } from 'react'
import styled from 'styled-components'
import SoundWaveGif from '../../../common/assets/Sound-wave.gif'
import SoundWaveGifOff from '../../../common/assets/Sound-Wave-off.gif'
import usePageVisibility from '../../../hooks/usePageVisibility'
import { useGlobalSoundSettings } from '../../../context/GlobalSoundSettingsContext'

const Wave = styled.img`
    width: 54px;
    height: 54px;
`

function MuteSound({ className }) {
    const { mute, setMute, playing } = useGlobalSoundSettings()

    const isPageVisible = usePageVisibility()

    useEffect(() => {
        window?.Howler?.mute(mute || !isPageVisible)
    }, [mute, isPageVisible])

    const onClick = (e) => {
        setMute(!mute)
    }

    return (
        <div className={`cursor-pointer centerItem  ${className || ''}`} onClick={onClick}>
            <Wave src={(mute || !playing) ? SoundWaveGifOff : SoundWaveGif} alt="audio" />
        </div>
    )
}

export default React.memo(MuteSound)
