import './utils/wdyr'
import './utils/gsapExtensions'
import { hot } from 'react-hot-loader/root'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { GlobalStyle } from './common/styles'
import { GlobalProvider } from './context'
import { SoundProvider } from './context/SoundContext'
import { VoiceoverProvider } from './context/VoiceOverContext'
import Views from './views/index'
import './i18n'
import { GlobalSoundSettingsProvider } from './context/GlobalSoundSettingsContext'

const App = hot(() => {
    return (
        <>
            <GlobalStyle />
            <GlobalSoundSettingsProvider>
                <GlobalProvider>
                    <SoundProvider>
                        <VoiceoverProvider>
                            <Router basename={process.env.PUBLIC_URL}>
                                <Views />
                            </Router>
                        </VoiceoverProvider>
                    </SoundProvider>
                </GlobalProvider>
            </GlobalSoundSettingsProvider>
        </>
    )
})

const root = document.createElement('div')
root.className = 'application'
document.body.appendChild(root)

ReactDOM.render(<App />, root)
