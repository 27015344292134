import { useEffect, useRef } from 'react'

export default function useWindowResize(onResize) {
    const currentOnResizeRef = useRef(onResize)
    currentOnResizeRef.current = onResize

    useEffect(() => {
        const internalOnResize = () => currentOnResizeRef.current && currentOnResizeRef.current(!document.hidden)
        window.addEventListener('resize', internalOnResize)
        return () => window.removeEventListener('resize', internalOnResize)
    }, [])
}
