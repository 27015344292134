export const baseColors = {
    white: '#FFFFFF',
    black: '#000000',
    grey: { light: '#9A9C9E', base: '#414141' },
    mint: '#74FBC8',
    green: '#6CCC86',
    glitch: '#95C7C7',
    watermelon: '#FF477E',
    purple: '#7781DB',
    red: '#C54D5C',
    yellow: '#F8BF6C',
    orange: '#EB7402',
    pink: '#DC0096',
    blue: {
        light: '#AAD6FF',
        base: '#5C76FF',
        medium: '#337F9B',
        dark: '#1E1F34',
        veryDark: '#080615',
        ultraDark: '#06060E',
    },
    valueDriver: {
        green: {
            main: '#B0E1DB',
            gradient1: '#7CFBA7',
            gradient2: '#6CC1FF',
            shadow: '#94CA82',
            placeholder: '#26343A',
        },
        gold: {
            main: '#FFED9A',
            gradient1: '#FFCE54',
            gradient2: '#FCA2DD',
            shadow: 'rgba(249, 213, 122, 0.49)',
            placeholder: '#383932',
        },
        blue: {
            main: '#79BFFF',
            gradient1: '#4E8FE7',
            gradient2: '#AF5DFC',
            shadow: 'rgba(185, 98, 253, 0.35)',
            placeholder: '#1E3149',
        },
    },
}

export const colors = {
    background: '#0f173f',
    accent: baseColors.mint,
    titleColor: baseColors.blue.light,
    font: { strong: baseColors.white, medium: baseColors.grey.light, light: baseColors.grey.base },
    borderColor: '#5E8AFB',
    cursor: '#5E8AFB',
    button: {
        outerCircleFillHover: '#3861D8',
        innerCircleFillHover: 'rgba(159,184,255, 0.2)',
        primary: {
            color: '#25355D',
            colorHover: '#FFFFFF',
            background: '#FFFFFF',
            backgroundHover: '#233B98',
        },
        secondary: {
            color: '#FFFFFF',
            colorHover: '#233B98',
            background: '#25355D',
            backgroundHover: '#FFFFFF',
        },
    },
    implementButton: {
        borderColor: '#4DAEDE',
    },
    analyzing: {
        strokeColor: '#4DAEDE',
        shadowColor: '#6F6CFE',
    },
    dashboard: {
        top: '#FFED9A',
        bottom: '#79BFFF',
        green: '#B8E5AD',
    },
}
