import React, { useCallback, useMemo } from 'react'
import { globalReducer,
    initGlobalState,
    getViewByIndexOrName,
    UPDATE_VIEW_DATA,
    SET_VIEW_INDEX_STATE,
    SET_VALUE_DRIVER_COORDINATES,
    UPDATE_VALUE_DRIVER } from './globalReducer'

const defaultGlobalValue = {
    state: initGlobalState,
    setViewIndex: (index) => {},
    /**
    * @param {number|string} index index or view name
    * @returns {object} view data
    */
    getViewData: (index) => {},
    /**
     * @param {number|string} index index or view name
     * @param {object} data view data
     */
    updateViewData: (index, data) => {},
    updatePosition: (id, x, y) => {},
    /**
     * update the value driver coordinates from the current view
     * @param {Array<{x: number, y: number}>} valueDriverCoordinates pass all value driver dots coordinates to update
     */
    setValueDriverCoordinates: (valueDriverCoordinates) => {},
    /**
     * update the value driver data from the current view
     * @param {number} index index of the value driver
     * @param {object} data value driver data
     */
    updateValueDriverData: (index, data) => {},
}

const GlobalContext = React.createContext(defaultGlobalValue)
GlobalContext.displayName = 'GlobalContext'

export const useGlobalContext = () => React.useContext(GlobalContext)
export default GlobalContext

export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(globalReducer, initGlobalState)

    const updateViewData = useCallback((index, data) => {
        dispatch({ type: UPDATE_VIEW_DATA, index, data })
    }, [])

    const setViewIndex = useCallback((index) => {
        dispatch({ type: SET_VIEW_INDEX_STATE, index })
    }, [])

    const setValueDriverCoordinates = useCallback((valueDriverCoordinates) => {
        dispatch({ type: SET_VALUE_DRIVER_COORDINATES, valueDriverCoordinates })
    }, [])

    const updateValueDriverData = useCallback((index, data) => {
        dispatch({ type: UPDATE_VALUE_DRIVER, index, data })
    }, [])

    const getViewData = useCallback((index) => {
        const name = getViewByIndexOrName(index).name
        return state.viewData[name]
    }, [])

    const value = useMemo(() => ({
        state,
        setViewIndex,
        updateViewData,
        getViewData,
        setValueDriverCoordinates,
        updateValueDriverData,
    }), [state])

    return (
        <GlobalContext.Provider value={value}>
            {children}
        </GlobalContext.Provider>
    )
}
