import { createGlobalStyle, css } from 'styled-components'
import { hot } from 'react-hot-loader'
import { normalize } from 'styled-normalize'
import { primaryFontStyle, textStyle } from './text'
import Animations from './animations'
import { colors } from './color'

const fullViewport = css`
  width: 100%;
  min-height: 100vh;
  -webkit-overflow-scrolling: touch;
`

export const zIndex = {
    debug: 10100,
    cursor: 11000,
    modalAndNotifications: 10500,
    menu: 10016,
    overlayContent: 10000,
    pins: [9999, 0],
}

export const GlobalStyle = hot(module)(createGlobalStyle`
  :root {
    --tp-base-background-color: hsla(0, 0%, 10%, 0.8);
  }

  ${normalize};
  ${textStyle};
  body {
    ${primaryFontStyle()};
    //{secondaryFontFace()}
    font-size: 16px;
    word-break: break-word;
    position: relative;
    ${fullViewport};
    color: #fff;
    background: ${colors.background};
    user-select: none;
    overflow:hidden;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;

    ul, li {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    font-weight: normal;
    font-style: normal;
    letter-spacing: 0.52px;

    -ms-overflow-style: none;  // IE 10+
    overflow: -moz-scrollbars-none;  // Firefox
    
    ::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
  }
  * {
    touch-action: pan-x pan-y;
  }
  *, *:before, *:after {
    box-sizing: border-box;
  }
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
    color: #fff;
  }

  .application {
    position: fixed;
    width: 100%;
    height: 100%;
  }

  .truste-footer,.truste-left a  {
    color:#3C3C3C;
  }
  
  .truste-consent-track-class *, .truste_box_overlay * {
    cursor: auto!important;
  }

  .truste-consent-track-class {
    max-height:85vh !important;
    overflow-y: scroll;
  }
  #teconsent {
    display: none;
  }

  .none { display: flex; }
  .flex { display: flex; }
  .grid { display: grid; }

  .flex-col { flex-direction: column; }

  .items-start { align-items: flex-start; }
  .items-center { align-items: center; }
  .items-end { align-items: flex-end; }
  .justify-center { justify-content: center; }
  .place-items-center { place-items: center; }

  .flex-1 {
    flex: 1 1 0%;
  }

  .centerItem {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flex-auto { flex: auto }

  .absolute { position: absolute; }
  .relative { position: relative; }
  .fixed { position: fixed; }

  .w-full { width: 100%; }
  .h-full { height: 100%; }

  .p-4 { padding: 1rem; }
  .p-8 { padding: 2rem; }

  .cursor-pointer { cursor: pointer; }

  .uppercase { text-transform: uppercase; }

  .pointer-events-none { pointer-events: none; }

  .p-1 { padding: 0.25rem; }
  .m-1 { margin: 0.25rem; }
  .m-2 { margin: 0.5rem; }
  .m-8 { margin: 2rem; }

  .text-center { text-align: center; }

  .z--1 { z-index: -1 }
  .z-0 { z-index: 0 }
  .z-1 { z-index: 1 }

  .tp-brkv, #tweakpane { 
    /* display: none; */
    * {
      cursor: auto!important;
    }
  }

  #tweakpane {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
  }
  
  .overflow-y-scroll { 
    overflow-y: scroll; 
  }
  
  ${Animations()}
`)

export const center = {
    horizontal: () => css`
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  `,
    vertical: () => css`
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
  `,
    absolute: () => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  `,
}
