//import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import contactUsIcon from '../../common/assets/contactUsIcon.png'
//import ContactUsDetail from './ContactUsDetail'

//const contactDataUrl = 'https://www.sap.com/bin/sapdxc/cache/contactModule/content/sapdx/countries/en_us/_jcr_content/parContactModule/contactModule.model.json'

export const Container = styled.div`
    position: fixed;
    top: 50%;
    right: 0;
    white-space: pre;

    @media only screen and (max-height: 550px) and (max-width: 420px) {
        transform: scale(0.8);
    }

    @media only screen and (max-height: 500px) and (max-width: 420px) {
        top: 55%;
        transform: scale(0.6);
    }

    .close {
        width:100%;
        height:100%;
    }
`

export const Blue = styled.div`
    position:absolute;
    right:0px;
    top:50%;
    transform: translate(0, -50%);
    background-color: #007DB8;  
    padding:10px 30px;
    display:block;
    text-align:center;
    
    .contact-us-icon {
        margin: 5px auto 5px;
        width: 32px;
    }

    .open {
        cursor:pointer;
    }
`

const ContactUs = ({ className }) => {
    const { t } = useTranslation()
    return (
        <Container className={'z-1 ' + (className ?? '')}>
            <a href="https://www.sap.com/registration/contact.html?product=73555000100700000452&productName=SAP+S%2F4HANA+CLOUD&topicarea=Solutions" target="_blank" rel="noreferrer">
                <Blue>
                    <div className="open">
                        <img src={contactUsIcon} alt="contact-us" className="contact-us-icon" />
                        <div>{t('endScreen.contactUs')}</div>
                    </div>
                </Blue>
            </a>
        </Container>
    )
}

ContactUs.propTypes = {

}

export default ContactUs
