import styled from 'styled-components'
import { ReactComponent as RestartIcon } from './RestartIcon.svg'

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    width: 35px;
    height: 35px;

`

function Restart() {
    const onClick = (e) => {
        if (document.webkitFullscreenElement) {
            document.webkitExitFullscreen()
        }
        window.history.pushState({ }, null, '/')
        window.history.go()
    }

    return (

        <Container>
            <RestartIcon onClick={onClick} />
        </Container>
    )
}

export default Restart
