import { useEffect } from 'react'
import useCurrentView from '../../hooks/useCurrentView'

const Metrics = () => {
    const url = window.location.href
    const baseSection = 'dmc-exp-2022-01-decoding-success'
    const currentView = useCurrentView()

    useEffect(() => {
        if (currentView?.index > 1) {
            const customEvent = new CustomEvent('trackData', {
                detail: { events: {
                    pageView: true,
                },
                page: {
                    url,
                    section: baseSection,
                    detail: currentView?.detail,
                    language: 'en',
                    country: 'glo',
                } },
            })
            document.body.dispatchEvent(customEvent)
        }
    }, [currentView])
    return null
}
export default Metrics
