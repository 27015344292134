import React, { useMemo } from 'react'
import { isMobileOnly, useMobileOrientation } from 'react-device-detect'
import styled from 'styled-components'
import Lottie from 'react-lottie'
import animationData from './assets/rotate_animation.json'
import { colors } from '../../common/color'

const Overlay = styled.div`
    background: #0C1524F2; // 95% opacity
    z-index: 2000;
    display: grid;
    place-content: center;
    text-align: center;
    h3 {
        color: ${colors.titleColor};
        font-family: '72 Light';
        font-size: 35px;
        margin-bottom: 12px;
        margin-top: 18px;
    }
    h4 {
        font-size: 15px;
        font-family: '72 Regular';
        margin-top:0;
    }
`

function ScreenOrientationLock() {
    const { isLandscape } = useMobileOrientation()

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet',
        },
    }

    const overlay = useMemo(() => {
        return (

            <Overlay className="fixed w-full h-full">
                <Lottie
                    options={defaultOptions}
                    width={55}
                    height={55}
                    isStopped={false}
                    isPaused={false}
                    direction={-1}
                    style={{ transform: 'scale3d(-1, 1, 1)' }}
                />
                <h3>Only portrait mode is supported!</h3>
                <h4>Please turn your phone</h4>
            </Overlay>

        )
    }, [])

    return isLandscape && isMobileOnly && window.innerHeight < 500 ? overlay : null
}

export default React.memo(ScreenOrientationLock)
