import { css } from 'styled-components'
import { PAGE_TRANSITION_ANIMATION_TIMEOUT, MAINVIEW_ZOOM_TRANSITION_TIME } from '../constants'

const Animations = () => css`
  @keyframes shake-vertical {
    0%,
    100% {
      -webkit-transform: translate3d(0);
              transform: translate3d(0);
    }
    10%,
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(0, -8px, 0);
              transform: translate3d(0, -8px, 0);
    }
    20%,
    40%,
    60% {
      -webkit-transform: translate3d(0, 8px, 0);
              transform: translate3d(0, 8px, 0);
    }
    80% {
      -webkit-transform: translate3d(0, 6.4px, 0);
              transform: translate3d(0, 6.4px, 0);
    }
    90% {
      -webkit-transform: translate3d(0, -6.4px, 0);
              transform: translate3d(0, -6.4px, 0);
    }
  }

  @keyframes shake-horizontal {
    0%,
    100% {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    10%,
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-0.3%, 0, 0);
              transform: translate3d(-0.3%, 0, 0);
    }
    20%,
    40%,
    60% {
      -webkit-transform: translate3d(0.3%, 0, 0);
              transform: translate3d(0.3%, 0, 0);
    }
    80% {
      -webkit-transform: translate3d(0.2%, 0, 0);
              transform: translate3d(0.2%, 0, 0);
    }
    90% {
      -webkit-transform: translate3d(-0.2%, 0, 0);
              transform: translate3d(-0.2%, 0, 0);
    }
  }

  @keyframes shake-top {
    0%,
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      -webkit-transform-origin: 50% 0;
              transform-origin: 50% 0;
    }
    10% {
      -webkit-transform: rotate(2deg);
              transform: rotate(2deg);
    }
    20%,
    40%,
    60% {
      -webkit-transform: rotate(-4deg);
              transform: rotate(-4deg);
    }
    30%,
    50%,
    70% {
      -webkit-transform: rotate(4deg);
              transform: rotate(4deg);
    }
    80% {
      -webkit-transform: rotate(-2deg);
              transform: rotate(-2deg);
    }
    90% {
      -webkit-transform: rotate(2deg);
              transform: rotate(2deg);
    }
  }

  .shake-vertical {
    animation: shake-vertical 2s cubic-bezier(0.455, 0.030, 0.515, 0.955);
  }

  .shake-horizontal {
    animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955);
  }

  .shake-top {
    animation: shake-top 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955);
  }

  @keyframes content-fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes text-focus-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes text-blur-out {
    0% {
      opacity: 1.0
    }
    100% {
      opacity: 0.0
    }
  }

  @keyframes text-blur-out-scale-up {
    0% {
      opacity: 1.0
      transform: scale(1);
    }
    100% {
      opacity: 0.0
      transform: scale(2);
    }
  }

  @keyframes scale-in {
    0% {transform: scale(0);} 
    100% {transform: scale(1);} 
  }

  @keyframes scale-out-center {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0);
      opacity: 1;
    }
  }

  @keyframes scale-out-center_text-blur-out {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes slide-in-fwd-center {
    0% {
      transform: translateZ(-1400px);
      opacity: 0;
    }
    100% {
      transform: translateZ(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-fwd-center_text-focus-in {
    0% {
      transform: translateZ(-1400px);
      opacity: 0;
    }
    100% {
      transform: translateZ(0);
      opacity: 1;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .slide-in-fwd-center {
    animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .scale-in {
    animation: scale-in 1s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  }
  
  .text-blur-out {
    animation: text-blur-out 1.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  .fade-enter {
    animation: text-focus-in 0.4s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  .fade-exit {
    animation: text-blur-out 0.4s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  .page-fade-enter {
    animation: content-fade-in ${PAGE_TRANSITION_ANIMATION_TIMEOUT}ms cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  .page-fade-exit {
    animation: text-blur-out ${PAGE_TRANSITION_ANIMATION_TIMEOUT}ms cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  .page-main-to-detail-enter {
    animation: text-focus-in ${PAGE_TRANSITION_ANIMATION_TIMEOUT}ms cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  .page-main-to-detail-exit {
    animation: text-blur-out-scale-up ${MAINVIEW_ZOOM_TRANSITION_TIME}ms cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  .fadeTransition-enter {
    animation: fade-in ${MAINVIEW_ZOOM_TRANSITION_TIME}ms linear both;
  }
  
  .fadeTransition-exit {
    animation: fade-out ${MAINVIEW_ZOOM_TRANSITION_TIME}ms linear both;
  }
`

export default Animations
