import { useState, useEffect } from 'react'
import { TIME_SCALE } from '../../constants'
import { useSleep } from '../../hooks'

const withDelay = (WrappedComponent, delay = 0) => ({ ...props }) => {
    const sleep = useSleep()
    const [show, setShow] = useState(delay <= 0)

    useEffect(() => {
        sleep(delay / TIME_SCALE).then(() => setShow(true))
    }, [])

    return show ? <WrappedComponent {...props} /> : null
}

export default withDelay
