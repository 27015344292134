import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useGlobalSoundSettings } from '../context'
import SplashScreen from '../components/SplashScreen'
import { Views } from '../constants'
import withDelay from '../hoc/withDelay/withDelay'

const LandingView = () => {
    const history = useHistory()
    const { play, setPlay } = useGlobalSoundSettings()

    useEffect(() => {
        play && setPlay(false)
    }, [])

    const onSlideEnd = () => {
        setPlay(true)
        history.push('/intro')
    }

    return (
        <SplashScreen onSlideEnd={onSlideEnd} />
    )
}

export default withDelay(LandingView, Views.LandingView.showInDelay)
