import gsap from 'gsap'
import { TIME_SCALE } from '../constants'

gsap.config({
    force3D: true,
})

gsap.globalTimeline.timeScale(TIME_SCALE)

gsap.core.Timeline.prototype.wait = function wait(duration = 1, position) {
    return this.to({}, { duration }, position)
}

gsap.core.Timeline.prototype.callWithDuration = function callWithDuration(callback, duration = 1, position) {
    return this.to({}, { duration, onStart: () => callback() }, position)
}

gsap.registerEffect({
    name: 'fadeOut',
    effect: (targets, config) => {
        return gsap.to(targets, { duration: config.duration, opacity: 0 })
    },
    defaults: { duration: 1 }, //defaults get applied to any "config" object passed to the effect
    extendTimeline: true, //now you can call the effect directly on any GSAP timeline to have the result immediately inserted in the position you define (default is sequenced at the end)
})

gsap.registerEffect({
    name: 'fadeIn',
    effect: (targets, config) => {
        return gsap.to(targets, { duration: config.duration, opacity: 1 })
    },
    defaults: { duration: 1 }, //defaults get applied to any "config" object passed to the effect
    extendTimeline: true, //now you can call the effect directly on any GSAP timeline to have the result immediately inserted in the position you define (default is sequenced at the end)
})
