import { useEffect, useState } from 'react'

export default function usePageVisibility() {
    const [isPageVisible, setPageVisibility] = useState(true)

    useEffect(() => {
        const toggleVisibility = () => setPageVisibility(!document.hidden)

        document.addEventListener('visibilitychange', toggleVisibility)

        return () => document.removeEventListener('visibilitychange', toggleVisibility)
    }, [])

    return isPageVisible
}
