import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Views } from '../constants'

const viewsValues = Object.values(Views)

/**
 * returns the current view based on the location
 * @returns {{
 * index: number,
 * name: string,
 * label: string,
 * path: string,
 * detail: string,
 * }}
 */
export default function useCurrentView() {
    const [currentView, setCurrentView] = useState(null)
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        const view = viewsValues
            .find(viewItem => viewItem.sameViewForNestedRoutes
                ? location.pathname.startsWith(viewItem.path)
                : location.pathname === viewItem.path)
        if (view === undefined) history.push(Views.LandingView.path)
        setCurrentView(view)
    }, [location])

    return currentView
}
