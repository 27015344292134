import React, { useEffect, useRef } from 'react'

const fpsValues = { prev: 0, averageFpsValue: 0, averageFpsCount: 0 }
const FpsMonitor = () => {
    const spanRef = useRef()
    const raf = useRef()

    useEffect(() => {
        const render = (ms) => {
            const delta = ms - fpsValues.prev
            fpsValues.prev = ms
            const fpsValue = 1000.0 / delta

            fpsValues.averageFpsValue += fpsValue
            fpsValues.averageFpsCount++
            if (fpsValues.averageFpsCount > 60) {
                const aveFps = Math.round(fpsValues.averageFpsValue / 60.0)
                if (spanRef.current) spanRef.current.innerText = aveFps
                fpsValues.doneWithAverage = true
                fpsValues.averageFpsValue = 0
                fpsValues.averageFpsCount = 0
            }
            raf.current = requestAnimationFrame(render)
        }
        render(0)

        return () => {
            if (raf.current) {
                cancelAnimationFrame(raf.current)
            }
        }
    }, [])

    return (
        <div style={{ position: 'fixed', margin: '10px 0 0 100px', zIndex: 10000 }}>
            Fps:
            {' '}
            <span ref={spanRef} />
        </div>
    )
}

export default React.memo(FpsMonitor)
