import { useEffect } from 'react'
import { SPRITES } from '../../constants'
import { useSoundContext } from '../../context'

export default function useClickSound() {
    const [play] = useSoundContext()

    useEffect(() => {
        if (play) {
            const onClick = () => {
                play(SPRITES.select)
            }
            window.addEventListener('click', onClick)
            return () => {
                window.removeEventListener('click', onClick)
            }
        }
        return null
    }, [play])

    return null
}
